import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersCRUDService {
  constructor(private httpClient: HttpClient) {}
  baseUrl = environment['host'];
  urlbot = environment['bot'];
  headers = new HttpHeaders({
    'Content-type': 'application/json',
  });
  // create(data:any){
  //     const baseUrl = environment['host'] + '/user'
  //     const body = JSON.stringify(data);
  //     return this.httpClient.post(baseUrl, body, { headers: this.headers, observe: 'response' })
  // }

  createForCheckout(data: any) {
    const baseUrl = this.baseUrl + '/user_checkout';
    const body = JSON.stringify(data);
    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
    });
  }
  createUser(data: any) {
    const baseUrl = this.baseUrl + '/create_user';
    const body = JSON.stringify(data);
    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getUserById(id: any) {
    const baseUrl = this.baseUrl + '/user_checkout';
    // console.log('JSON.parse(id)', JSON.parse(id));
    let options: HttpParamsOptions = { fromObject: { id: id, otro: 'otro' } };
    // options.fromString='id=id'
    // const params:HttpParams=new HttpParams({'fromString':'id='+JSON.parse(id).id.toString()})
    const params = new HttpParams(options);
    console.log('PARAMS ', params);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      params: params,
    });
  }
  isenrolled(email: string, product: string) {
    const _params = {
      email: email,
      product: product,
    };
    const params = new HttpParams({ fromObject: _params });
    const baseUrl = this.baseUrl + '/isenrolled';
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      params: params,
      observe: 'response',
    });
  }
  checkCupon(data: any) {
    const baseUrl = this.baseUrl + '/check_cupon';
    const body = JSON.stringify(data);
    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getUsers() {
    const baseUrl = this.baseUrl + '/get_users';
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  getEndpoint(endpoint: string) {
    const baseUrl = this.baseUrl + endpoint;
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  postEndpoint(endpoint: string, data: any) {
    const baseUrl = this.baseUrl + endpoint;
    const body = data;

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  getProducts(course?: any) {
    const baseUrl = this.baseUrl + '/get_products';
    let options: HttpParamsOptions = {};
    let withCredentials = true;
    if (course != undefined) {
      withCredentials = false;
      options = { fromObject: { course: course } };
    }

    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: withCredentials,
      params: params,
    });
    // return this.httpClient.post(baseUrl, {
    //   headers: this.headers,
    //   observe: 'response',
    //   withCredentials:withCredentials,
    //   params: params,
    // });
  }
  getLaunches(_params:any={}) {
    const baseUrl = this.baseUrl + '/get_launches';
    let options: HttpParamsOptions = {};
    let withCredentials = true;
    
    if (Object.keys(_params).length>0){
      withCredentials = false
      options = {fromObject:_params}
      console.log('_params',_params);

    }
    // if (launch != undefined) {
    //   withCredentials = false;
    //   options = { fromObject: { launch: launch } };
    // }

    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: withCredentials,
      params: params,
    });

  }

  createLaunch(data:any){
    const baseUrl = this.baseUrl + '/launch';
    const body = data;
    console.log(data);
    (data)
    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }

  ernoll(data: any) {
    const baseUrl = this.baseUrl + '/enroll';
    const body = data;

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  enroll_mc(data: any) {
    const baseUrl = this.baseUrl + '/enroll_mc';
    const body = data;

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
    });
  }
  discountlink(data: any) {
    const baseUrl = this.baseUrl + '/discount_link';
    const body = data;

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  generatelink(data: any) {
    const baseUrl = this.baseUrl + '/generate_link';
    const body = data;

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      // withCredentials: true,
    });
  }
  checkCode(code: string) {
    const baseUrl = this.baseUrl + '/check_code';
    let options: HttpParamsOptions = { fromObject: { code: code } };
    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      params: params,
    });
  }
  whois(data: any) {
    // const baseUrl = this.baseUrl + '/whois';
    const baseUrl = this.urlbot + '/whois';
    const body = data;
    // console.log('Generate_link',data);

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
    });
  }
// -------------------------------------------------------------------------------------------------------
  setEntity(data:any){
      // const baseUrl = this.baseUrl + '/whois';
      const baseUrl = this.urlbot + '/entity';
      const body = data;
      // console.log('Generate_link',data);
  
      return this.httpClient.post(baseUrl, body, {
        headers: this.headers,
        observe: 'response',
      });
  }

  getEntity(id?: any) {
    // const baseUrl = this.baseUrl + '/product';
    const baseUrl = this.urlbot + '/entity';
    
    let options: HttpParamsOptions = {};
    let withCredentials = true;
    if (id != undefined) {
      withCredentials = false;
      options = { fromObject: { _id: id } };
    }

    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: withCredentials,
      params: params,
    });

  }

  getInstallements(cycle: string) {
    // const baseUrl = this.baseUrl + '/product';
    const baseUrl = this.urlbot + '/installements';
    
    let options: HttpParamsOptions = {};
    let withCredentials = false;
    options = { fromObject: { "cycle": cycle } };

    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: withCredentials,
      params: params,
    });

  }
  

  getIpInfo(ip: string) {
    const baseUrl = 'https://ipinfo.io/${ip}/json';
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getwaids(){
    const baseUrl = this.urlbot + 'waids';
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  getwaMessagesById(waid:string){
    const baseUrl = this.urlbot + 'wamessages';
    const body = {waid:waid};

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }
  getProduct(product?: any) {
    // const baseUrl = this.baseUrl + '/product';
    const baseUrl = this.urlbot + '/product';
    
    let options: HttpParamsOptions = {};
    let withCredentials = true;
    if (product != undefined) {
      withCredentials = false;
      options = { fromObject: { product: product } };
    }

    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      withCredentials: withCredentials,
      params: params,
    });

  }
}
