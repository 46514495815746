<div class="container">
    <h1>Habilitar Cookies</h1>
    <p>Para continuar usando nuestro sitio web, es necesario habilitar las cookies en su navegador.</p>
    <p>Las cookies nos permiten brindarle una mejor experiencia y garantizar el correcto funcionamiento de nuestra página.</p>
    <p>Por favor, siga las siguientes instrucciones para habilitar las cookies en su navegador:</p>
    <ul>
      <li><strong>Google Chrome:</strong> Configuración > Privacidad y seguridad > Cookies y otros datos de sitios > Permitir todas las cookies.</li>
      <li><strong>Mozilla Firefox:</strong> Opciones > Privacidad y seguridad > Cookies y datos del sitio > Aceptar cookies y datos del sitio.</li>
      <li><strong>Microsoft Edge:</strong> Configuración > Cookies y permisos de sitio > Administrar y eliminar cookies y datos del sitio > Permitir todas las cookies.</li>
      <li><strong>Safari:</strong> Preferencias > Privacidad > Bloquear todas las cookies (desmarcar).</li>
    </ul>
    <p>Después de habilitar las cookies, por favor recargue la página para continuar.</p>
  </div>