import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { range } from 'rxjs';
import { UsefullFunctionsService } from 'src/app/shared/services/usefull-functions.service';
import { UsersCRUDService } from 'src/app/shared/services/usersCRUD.service';
import { WaitingComponent } from '../../waiting/waiting.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { CheckoutSubscriptionsComponent } from '../../buyingProcess/checkouts/checkout-subscriptions/checkout-subscriptions.component';
import COUNTRIES from 'src/app/shared/data';

@Component({
  selector: 'app-create-subscription-form',
  templateUrl: './create-subscription-form.component.html',
  styleUrls: ['./create-subscription-form.component.css'],
})

export class CreateSubscriptionFormComponent implements OnInit {
  public countries = COUNTRIES;

  constructor(private inject: Injector) {
    // let fun = this.inject.get(UsefullFunctionsService);
    const params = new URLSearchParams(window.location.search);
    
    if (params.has('cycle')){      
      this.dataFormGroup.controls.cycle.setValue(params.get('cycle'))
    }
    let usersCRUDService = this.inject.get(UsersCRUDService);
    const data = {
      url_visited:window.location.href+'_form'
    };
    let iso_code:string
    let dial_code:string
    usersCRUDService.whois(data).subscribe({
      next(res:any) {        
        iso_code=res.body['iso-code']
        dial_code='+'+res.body['dial_code']       
      },
      complete:()=>{
        // this.searchBox=iso_code
        
        this.dataFormGroup.controls.country.setValue(dial_code)
        
      }
    });

  }

  searchBox!: string;
  dataFormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    cycle :new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Za-z0-9._%+-]+@gmail.com/),
    ]),
    country: new FormControl('', [Validators.required]),
    mobile: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/[1-9]\d{6,14}/),
        Validators.maxLength(15),
        Validators.minLength(4),
      ])
    ),
    // ig_account: new FormControl('', [Validators.required]),
    installement: new FormControl('', [Validators.required]),
    accepts_c: new FormControl(false, [Validators.required]),
  });
  // message;
  cycles: { 
    name: string,
    image?:string
  }[] = [
    {name:'Gold',image:""},
    {name:'Platinum'},
    {name:'Bronze'},
  ];
  installements: { installement: number; price: number }[] = [];

  loadInstallements() {
    this.installements= []
    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
    };
    const userCrud = this.inject.get(UsersCRUDService);
    const dialog = this.inject.get(MatDialog);
    const modal = this.inject.get(ModalService);
    const cycle = this.dataFormGroup.controls.cycle.value!.toUpperCase()
    if (cycle){
      modal.modalForm(dialog, WaitingComponent, options);
      userCrud.getInstallements(cycle).subscribe({
        next: (value) => {
          
          this.installements = (value.body as any)['cycle']['cycles'][0]['installements']
          
        },
        complete:()=>{
          modal.close(dialog)
        }
      })
    }

    
  }
  
  getErrors(dataFormControl: FormControl) {
    if (
      'minlength' in dataFormControl.errors! ||
      'maxlength' in dataFormControl.errors!
    ) {
      return 'Ingresa tu número en formato intl.';
    }
    if ('required' in dataFormControl.errors!) {
      return 'Ingresa un número';
    }
    return null;
  }


  ngOnInit(): void {
    let images = [
      {name:'Gold',img:'../../../assets/images/Banner landing MCI.webp'},
      {name:'Platinum',img:'../../../assets/images/Banner landing NMS.jpg'},
      {name:'Bronze',img:'../../../assets/images/Banner landing SSP.jpg'}
    ]
    const img = document.querySelector('#banner') as HTMLImageElement

    setImage(this.dataFormGroup.controls.cycle.value!,images,img)
    this.loadInstallements()
    this.dataFormGroup.controls.cycle.valueChanges.subscribe({
      next:(value) => {
        setImage(value!,images,img)
        this.installements = []
        this.loadInstallements()

      }
    })
    const userCrud = this.inject.get(UsersCRUDService);
    const params = {
      // 'launch':this.message['launch_id'],
      'date':new Date().toISOString()
    }
    // userCrud.getLaunches(params).subscribe({
    //   next: (v) => {
    //     const { launches } = v.body as any;

    //     console.log(launches);
        
    //     // for (let index = 0; index < launches['prices'].length; index++) {
    //     //   const element :any = launches['prices'][index];
    //     //   console.log('element',element['to']);
          
    //     //   const from = new Date(element['from']).getTime()
    //     //   const to = new Date(element['to']).getTime()
    //     //   console.log('index',index);
    //     //   console.log(`${from}<${now} ${from<now}`);          
    //     //   console.log(`${to}>${now} ${to>now}`);          
                    
    //     //   // if (element['from']> new Date().toISOString() )
          
          
    //     // }        
    //     // const to = new Date(launches[0]['to']).toISOString()
    //     // const today = new Date().toISOString()
    //     // console.log('to',to);
    //     // console.log('today',today);


    //     const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    //     const prices=launches['prices'][0]
    //     const to = new Date(prices['to']).getTime();
    //     const today = Date.now();

    //     const diff = to - today;
    //     const diffInDays = Math.floor(diff / millisecondsInOneDay);
    //     const daysOfMonth = 30;

    //     const installements = Math.floor(diffInDays / daysOfMonth) + 1;
    //     const interestRate = 1;

    //     if (prices['price'] - prices['price'] / installements >= 0) {
    //       for (let index = 0; index < installements; index++) {
    //         const amount = Math.ceil(
    //           prices['price'] / (index + 1) +
    //             (index * prices['price'] * interestRate) / 100
    //         );
    //         this.installements.push({
    //           installement: index + 1,
    //           amount: amount,
    //         });
    //       }
    //     }
    //   },
    //   complete: () => {
    //   },
    // });
  }

  writeData(data: FormGroup) {
    console.log(data.value);

    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
    };
    const dataToSend: any = {};
    const dialog = this.inject.get(MatDialog);
    const modal = this.inject.get(ModalService);
    modal.modalForm(dialog, WaitingComponent, options);
    const usersCRUD = this.inject.get(UsersCRUDService);
    usersCRUD.setEntity({ personal_data: data.value }).subscribe({
      next: (value) => {},
      complete: () => {
        const stripe = this.inject.get(StripeService);
        stripe.getPublishableKey().subscribe({
          next: (v) => {
            const { public_key } = v.body as any;
            const { email } = data.value;

            dataToSend.publishableKey = public_key;
            dataToSend.email = email;
            // dataToSend.src = environment.src;
            dataToSend.name = data.controls['name'].value;
            dataToSend.installement = data.controls['installement'].value;
            dataToSend.cycle = data.controls['cycle'].value;
            dataToSend.mobile = data.controls['country'].value+data.controls['mobile'].value;
            // this.matDialogdata.mobile = data.controls['mobile'].value;
            // this.matDialogdata.city = data.controls['country'].value;
          },
          complete: () => {
            // if (!enrolled.state) {
            if (true) {
              modal.close(dialog);
              const options = {
                disableClose: false,
                autoFocus: true,
                width: 'auto',
                height: '650px',
                data: dataToSend,
              };
              modal.modalForm(dialog, CheckoutSubscriptionsComponent, options);
            }
          },
          error: (e) => console.error(),
        });
      },
    });
  }
  clearSearchBox(){
    this.searchBox=""
  }
}
function setImage(value:string,images:any,img:HTMLImageElement){
  
  for (let index = 0; index < images.length; index++) {
    const image : any = images[index];
    
    if (image['name'] == value){
      img.src = image['img']


    } 
    
  }
}