import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public matDialogdata: any) {}
  copied = false;
  link: string = '';
  ngOnInit(): void {
    this.link = this.matDialogdata.link;
  }
  simbol='content_copy'
  text = 'copy'
  cp_text() {
    const p = document.querySelector('#link') as HTMLParagraphElement;
    console.log('p?.textContent', p?.textContent);
    const textArea = document.createElement('textarea');
    textArea.select();
    navigator.clipboard
      .writeText(p?.textContent!)
      .then(() => {
        this.text='copied!'
        this.simbol='check'
        console.log('Text Copied');
      })
      .catch(() => {
        console.log('Unable to copy text');
      });
  }
}
