import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadScriptsService } from 'src/app/shared/services/loadScripts.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StripeService } from '../../../../services/stripe.service';
import { WaitingComponent } from '../../../waiting/waiting.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, AfterContentInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private stripe: StripeService,
    private loadScripts: LoadScriptsService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private modal: ModalService // private modal: ModalService
  ) {
    // this.src=this.matDialogdata.src
  }

  paymentHandler: any = null;
;
  price = 0;
  product = '';

  ngOnInit() {
    this.loadScripts.stripe();
    const img = document.querySelector('#product') as HTMLImageElement;
    img.src = this.matDialogdata.img;
    if ('couponState' in this.matDialogdata) {
      if (this.matDialogdata.couponState == 'valid') {
        this.openSnackBar('Cupon agregado satisfactoriamente', 'ok');
      } else {
        if (this.matDialogdata.couponState == 'expired') {
          this.openSnackBar('El cupon ya expiro', 'ok');
        } else {
          if (this.matDialogdata.couponState == 'invalid') {
            this.openSnackBar('Cupon no valido', 'ok');
          }
        }
      }
    }
  }

  openSnackBar(message: string, action: string) {

    const snackBarRef = this.snack.open(message, action, {
      duration: 3000,
    });
    snackBarRef.onAction().subscribe({
      next(value) {
      },
    });
  }

  ngAfterContentInit(): void {
    this.createPaymentIntent(this.matDialogdata, this.stripe);
  }
  createPaymentIntent(matDialogdata: any, stripe: StripeService) {
    const script = window.document.getElementById('stripe-script');
    let stripeJS: any;
    console.log(`matDialogdata.publishableKey ${matDialogdata.publishableKey}`);
    
    script!.onload = () => {
      stripeJS = (<any>window).Stripe(matDialogdata.publishableKey);
    };
    // const stripeJS = (<any>window).Stripe(this.matDialogdata.publishableKey);
    
    stripe.createPaymentIntent(matDialogdata).subscribe((v) => {
      const { clientSecret, price, product } = v.body as any;

      this.price = price / 100;
      this.product = product;

      // const stripe = (<any>window).Stripe(this.matDialogdata.publishableKey);

      const elements = stripeJS.elements({ clientSecret });

      const paymentElement = elements.create('payment');

      paymentElement.mount('#payment-element');

      const form = document.getElementById('payment-form');

      form!.addEventListener('submit', async (e) => {
        e.preventDefault();
        let return_url = ''
        // if (window.location.pathname.search('jhts')){
          
        // }
        // else{

        // }
        return_url = window.location.origin + window.location.pathname + '/payments/complete'
        const { error } = await stripeJS.confirmPayment({
          elements,
          confirmParams: {
            return_url:return_url,
          },
        });
        if (error) {
          console.log('in error in checkout');
          
          const messages = document.getElementById('error-messages');
          messages!.innerText = error.message;

          this.modal.close(this.dialog);
        }
      });
    });
  }

  processing() {
    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
      data: this.matDialogdata,
    };
    this.modal.modalForm(
      this.dialog,
      // this.matDialogdata,
      WaitingComponent,
      options
    );
  }
}
