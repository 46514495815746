export const environment = {
  production: false,
  // host:'https://apidev.elabossacademy.com','https://devchatbot.elabossacademy.com'
  host:'https://devchatbot.elabossacademy.com',
  product:'Pruebas',
  src:'',
  domain:'dev.elabossacademy.com',
  pub_key:`-----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtPk9vLRsjcGqH6S5fAIx
  a93peAEXm2RiO/poMjVAzC00WMaRlshkiCRAhjMTzfBJXz+1Uz1eGd7NyUWrO5dL
  0d5vM1/6BGYQ5CgAt6c4FRRkH/2CBavZwz/4gnbR29HHlvrt0TTE7ECOVKZsGzLx
  SersmBRVDjfLmFewcV2evXjux/2I4fJUXdVhrx6hRUj+oxKS34T5ta08gdBdgygv
  U9U0CtMlrzsx2TPpO+RolqlcoQom38H/JNd0u87bG8x7DhLIQPzrHPN0i+NSHu2l
  QL6F593/IoL/ox72MFrZuOnpOOpbmW467ymCsEPMe/oYL3yQv8O05UphX6pp8RoN
  zNLGBt49DWkA+LPSpJCDEQndy/fXlxBAzTBGbHLLXRnoGO2b8R5zU3heuJNW5saX
  +s6CEr0jl1YJ0+XROyK6UvaQBSV5Fj0SdPoTlTQi2m6BsucqIDct4DnWohH7b8bR
  j42RbWID9v8PjVcSMf5LKOJ52e/F/VBh28YQxhbfWFhStcuildWmTIsbus7iWRKO
  nURX9canr89DneQKvXrJCPMPbKATbAfCyIgWdlEgcgotCAYyzSqgZTBM4D/mBg0b
  EIzgPe/IeXX5Xo64t/dpkwTLaYCJKpFmEbnvFFBRgWaVQv2IWFwSpoumElJ4PTS0
  huNI5V6zoWCc7ETWkFVSbtkCAwEAAQ==
  -----END PUBLIC KEY-----`,
  
  priv_key:`-----BEGIN PRIVATE KEY-----
  MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC07ALUBkUnKPji
  DjJudmmZ7K2d843zNXy4BO9u8mPKGWhnmJn/T+dJkUSbzTqSA3ipAt+PRD1vRstu
  gtQU+KPuQSICm8yyoJ0mr/Y9ho6J6kSklRlDzu2pScVAMlbk32Vx2b3WGLOt78ZM
  fUuLnsT3Y9xOj+iPHCnRKUe2Ufliu/leqzsuoilgvFvJeKfDJGajtc4AiMXXbtbk
  MjPHFwOIkCrXh1StBYavRZc+lEl9VD2tYmAm1iKJ+QJYArRD2LgR4VEZHRhuy0yg
  6rJSttE0EeHMJnSM4nCkbVjR9ztf4f8Zjgsj4R7LpwSMZ8OGE5Z3HhQ8CQNuosRP
  wo/rtxsvAgMBAAECggEABx19CWLRvc+FS9NsTx6f2pDagLeL+2bWgW1lD5zywode
  eflTaeoPjSIfntNiz7LZQCeMktehMhe7qmkn90csBnoc4OaVr05UjKmNt2Lr7VqZ
  DOWPbJsRV7LYa6tPJYmJNZs8AjtO3bLsJPGMhgorC67etcXZ5Iu8AHptVCtD8b3X
  uGY+Z7o9Uw21WOIUHt8HAGNKgP1Gc58MeGP8z+zdEheLHDSUzBLKIIKrGYF05aCj
  mEmvyx9LFTFV9jqifMt3ri4fZV3DwEu/bEwleTzhGpbyz0oUmXr0q3JEV1jOQ9ye
  4XIQUVz5w5B0gOpT0TLvfqpx2JFWR4YlUt5mqHOhuQKBgQDzVRrVAuWIeqwn0pI9
  ok8qYPYLyawTQyXuN/ViXLAL2Pbu7peqIOHLT5d7MF4wkaYk1oN+uox4nIGOEnFG
  ataMpAEWIaYTiRiMWTixgDbQZKWC7X7YAuqYYqcww2lzkcDTl54LVD9EuykOdxxs
  zZNPc0thW4I6qORdLK1pPvIk9QKBgQC+VykFc3wVABAz8gfTvEgWZYYAhdPVbsUO
  UCNFebr/LfA+D3cV5HDT79Ikx2CMbVhbiIjvw76g1zxhWAr4qUcDoomkKarqJynU
  cEM9LHQM3IdtP4wR3YtoI4amPOJdSE+ANIyPDgTTjf9BdC+sbPB8QMFEG9M9ad2g
  OkCuuZLJEwKBgQDph8b9og8KQblRar0hW9GgeXgJ7h4wSmRv0NK2+P+YDz1GKEP0
  aiRpjFR0TQJImLAccKe4i27fHk0UPczq6QRJ6+iDBf4uh4fAwqwvZj61F7LlCeuP
  gpUFdMDCgviEiuDF8aAjfVJPM1R+5n+oSE/Vk3BH2pwMkiTA62zuMhQQtQKBgDG8
  is6O17yREKrSMrNkPp4Dl1lpnunlkP5leGH+JhvqAB3nYqHI2Ka4wvuzUYpARFde
  vRGR360dsPxqyish6JmNjlPkQ3H+G9MUJq3EgOqfcY/djBdbAcOLjPLBdBEWBRgm
  Ixh6fE5fi3SqDI8MxGZjcVz/vFoO4MeukRpXCYEbAoGBAOiDw3ocCctw7tWnVH8p
  /BTHgyWFRg9LBjj/GdNMr6kmYsoaG2XtY0HNwbjwEFNJhSXg046r0FR1jjz4V8op
  Jx0J1o+NqBPAX8AI6qVS8UdIgGTmkReKWmUb/AIhxTuZb8aQcRAsQESX8aHvcGBQ
  SRIl+Ht8L6VldTaYdYKBAFBN
  -----END PRIVATE KEY-----`,
  bot:'https://devchatbot.elabossacademy.com'

};
