import {
  AfterContentInit,
  Component,
  Inject,
  Injector,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { LoadScriptsService } from 'src/app/shared/services/loadScripts.service';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { environment } from 'src/environments/environment';
import { getCookie, setCookie } from 'typescript-cookie';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css'],
})
export class CompleteComponent implements OnInit, AfterContentInit {
  constructor(
    // @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private loadScripts: LoadScriptsService,
    private stripe: StripeService,
    private router: Router,
    private inject: Injector
  ) {}

  publishablekey: any;
  ngOnInit() {
    this.loadScripts.stripe();
  }
  ngAfterContentInit(): void {
    // if('free' in this.matDialogdata){
    if (false) {
      // this.freeAccess()
    } else {
      this.getPublishableKey(this.stripe);
    }

    // this.capture(this.publishablekey)
  }

  // capture(publishablekey:any){
  //   const script = window.document.getElementById('stripe-script');
  //   let stripeJS: any;
  //   script!.onload = () => {

  //     stripeJS = (<any>window).Stripe(publishablekey['publishablekey']);
  //     const params = new URLSearchParams(window.location.href);
  //     const clientSecret = params.get('payment_intent_client_secret');

  //     async function _capture(stripeJS: any) {
  //       const { paymentIntent } = await stripeJS.retrievePaymentIntent(
  //         clientSecret
  //       );

  //       const paymentIntentPre = document.getElementById('payment-intent');
  //       if (paymentIntent.status == 'succeeded') {
  //         paymentIntentPre!.innerText =
  //           'Tu pago fué procesado con exito, dentro de poco recibiras un correo con todas las instrucciones para acceder al curso';
  //       } else {
  //         paymentIntentPre!.innerText =
  //           'Tu pago no fué procesado con exito, or favor revisa tu balance de cuenta. En caso de existir un debito de dinero en tu cuenta, envianos un correo a <a href="mailto:mariana@elabossacademy.com">mariana@elabossacademy.com</a> lo mas pronto posible para verificar si el dinero ingreso a nuestra cuenta.';
  //       }

  //     }
  //     _capture(stripeJS);
  //   };
  // }

  // freeAccess(){
  //   if(this.matDialogdata.free){
  //     let dialog = this.inject.get(MatDialog)
  //     dialog.closeAll()

  //     const spinner = document.getElementById('spinner');
  //     spinner?.remove();
  //     const paymentIntentPre = document.getElementById('payment-intent');
  //     paymentIntentPre!.innerHTML =
  //               '🎉Gracias por tu compra! El acceso al mi Masterclass te llegará, en pocos minutos, al correo electrónico: <strong>' +
  //               this.matDialogdata.receipt_email +
  //               '</strong> que proporcionaste en el proceso de tu registro. <br><br><strong>*IMPORTANTE:</strong> si no encuentras el correo de acceso al curso, en tu bandeja de entrada principal; por favor debes buscar en correo no deseado. ¡Qué disfrutes <strong>' +
  //               this.matDialogdata.product +
  //               '</strong>!💛';

  //   }

  // }

  getPublishableKey(stripe: StripeService) {
    const script = window.document.getElementById('stripe-script');
    let stripeJS: any;

    script!.onload = () => {
      stripe.getPublishableKey().subscribe({
        next: (v) => {
          // this.publishablekey = (v.body as any)[0];
          this.publishablekey = v.body as any;
        },
        complete: () => {
          stripeJS = (<any>window).Stripe(this.publishablekey['public_key']);
          const product = document.location.pathname
            .split('/')[1]
            .toUpperCase()
            .replace('_', '');
          const params = new URLSearchParams(window.location.href);
          const clientSecret = params.get('payment_intent_client_secret');

          async function capture(stripeJS: any) {
            const data = await stripeJS.retrievePaymentIntent(clientSecret);
            const { paymentIntent } = data;
            const { receipt_email } = paymentIntent;

            // const { paymentIntent }=data receipt_email
            // const { paymentIntent } = await stripeJS.retrievePaymentIntent(
            //   clientSecret
            // );

            const spinner = document.getElementById('spinner');
            spinner?.remove();
            const paymentIntentPre = document.getElementById('payment-intent');
            if (paymentIntent.status == 'succeeded') {
              if (product == 'MCI') {
                paymentIntentPre!.innerHTML =
                  '🎉Gracias por tu compra! En pocos minutos,te llegará un correo electrónico, a la dirección: <strong>' +
                  receipt_email +
                  '</strong>, con la información sobre el acceso y pasos a seguir. <br><br><strong>*IMPORTANTE:</strong> si no encuentras el correo de acceso al curso, en tu bandeja de entrada principal; por favor debes buscar en correo no deseado. ¡Qué disfrutes mi Masterclass!💛';
              } else {
                paymentIntentPre!.innerHTML =
                  '🎉Gracias por tu compra! El acceso al curso te llegará, en pocos minutos, al correo electrónico: <strong>' +
                  receipt_email +
                  '</strong> que proporcionaste en el proceso de tu compra. <br><br><strong>*IMPORTANTE:</strong> si no encuentras el correo de acceso al curso, en tu bandeja de entrada principal; por favor debes buscar en correo no deseado. ¡Qué disfrutes <strong>' +
                  product +
                  '</strong>!💛';
              }
              const cookieName = '__' + product.toLowerCase() + '_access_time';
              if (getCookie(cookieName) && getCookie(cookieName) != 'null') {
                setCookie(cookieName, '', {
                  expires: -1,
                  path: '/' + product.toLowerCase(),
                  domain: environment.domain,
                });
              }
            } else {
              paymentIntentPre!.innerHTML =
                'Tu pago no fué procesado con exito, por favor revisa tu balance de cuenta. En caso de existir un debito de dinero en tu cuenta, envianos un correo a <a href="mailto:mariana@elabossacademy.com">mariana@elabossacademy.com</a> lo mas pronto posible para verificar si el dinero ingreso a nuestra cuenta.';
            }
            sessionStorage.removeItem('code');
          }
          capture(stripeJS);
        },
      });
    };
  }
  close() {
    const back = '/' + window.location.pathname.split('/')[1];
    this.router.navigate([back]);
  }
}
