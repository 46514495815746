import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ISearchParams } from 'src/app/shared/interfaces/types';
import { PathValidationService } from 'src/app/shared/services/path-validation.service';
import { UsefullFunctionsService } from 'src/app/shared/services/usefull-functions.service';
import { UsersCRUDService } from 'src/app/shared/services/usersCRUD.service';
import { WaitingComponent } from '../../waiting/waiting.component';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-redirect-telegram',
  templateUrl: './redirect-telegram.component.html',
  styleUrls: ['./redirect-telegram.component.css'],
})
export class RedirectTelegramComponent implements OnInit {
  uuidCookie: string | null;

  constructor(
    private inject: Injector,
    private modal: ModalService,
    private dialog: MatDialog,

  ) {
    const usefull = inject.get(UsefullFunctionsService);

    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
    };
    this.modal.modalForm(this.dialog, WaitingComponent, options);


    this.uuidCookie = usefull.getTrackingCookie('brwid');
    if (this.uuidCookie === null) {
      let browserId: string | null = null;

      browserId = Math.random().toString(36) + Date.now().toString(36);

      this.uuidCookie = usefull.setTrackingCookie('brwid', browserId, 365);
    }

    let data: ISearchParams = {};

    if (window.location.search.length > 0) {
      data = this.inject
        .get(PathValidationService)
        .getSearchParams(window.location.search);
    }
    
    const userCrudService = this.inject.get(UsersCRUDService);
    userCrudService.setEntity(data).subscribe({
      next: (value) => {
        
        usefull.setTrackingCookie('entity', (value.body as any)['id']);
      },
      complete: () => {
        console.log('data',data);
        if ('link' in data){
        const link=`https://t.me/${data['link']}`
          window.document.location.replace(link)
        }
        
        // window.document.location.replace('https://t.me/+WxkRwbdOlOdkYTNh');
      },
    });
  }

  ngOnInit(): void {}
}
