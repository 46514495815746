import { Injectable } from '@angular/core';
// import { Buffer } from 'buffer/';
// import * as crypto from 'crypto';
import * as Forge from 'node-forge';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CryptographyService {
  constructor() {
    // this._getFile(this.path_pub_key).subscribe(_pub_key=>{
    //   this.pub_key = _pub_key
    // })
  }

  encode(data_to_encript: string) {
    const rsa = Forge.pki.publicKeyFromPem(environment.pub_key);
    // "RSAES-PKCS1-V1_5" | "RSA-OAEP" | "RAW" | "NONE" |

    // Forge.util.encodeUtf8(data_to_encript)
    // console.log(rsa.encrypt(Forge.util.encodeUtf8(data_to_encript)));
    // console.log(rsa.encrypt(data_to_encript));

    return rsa.encrypt(data_to_encript, 'RSA-OAEP');
  }
  decode(encodedEncryptedData: string) {

    const  encryptedData=Forge.util.decode64(encodedEncryptedData)
    const key = Forge.pki.privateKeyFromPem(environment.priv_key);
    const decryptedData = key.decrypt(encryptedData, "RSA-OAEP");
    return decryptedData

  }

  getmessage(encodedEncryptedData:string){
    if (encodedEncryptedData != null) {
      encodedEncryptedData = encodedEncryptedData!
        .replace(/\./g, '/')
        .replace(/\-/g, '+');
      // let cryptographyService = this.inject.get(CryptographyService);

      const message = JSON.parse(
        this.decode(encodedEncryptedData!)
      );
      // sessionStorage.setItem('email', message['user']);
      // this.set_price(message['price'])
      return message
    }
  }

  // encode(data: string) {
  //   const pub_key = environment.pub_key;

  //   // let buffer = new Buffer(dencode_message);
  //   let encrypted = crypto.publicEncrypt(
  //     {
  //       key: pub_key,
  //       padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
  //       oaepHash: 'sha256',
  //     },
  //     Buffer.from(data)
  //   );
  //   return encrypted.toString('base64');
  // }

  // decode(encryptedData: string) {
  //   const priv_key = environment.priv_key;
  //   console.log(priv_key);

  //   const decryptedData = crypto.privateDecrypt(
  //     {
  //       key: priv_key,
  //       padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
  //       oaepHash: 'sha256',
  //     },
  //     Buffer.from(encryptedData)
  //   );
  //   console.log(decryptedData.toString('utf8'));

  //   return true;
  // }
}
