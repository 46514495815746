export const environment = {
    production: false,
    // host:'https://apidev.elabossacademy.com',
    host:'https://devchatbot.elabossacademy.com',
    product:'Pruebas',
    src:'',
    domain:'dev.elabossacademy.com',
    pub_key:`-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtPk9vLRsjcGqH6S5fAIx
    a93peAEXm2RiO/poMjVAzC00WMaRlshkiCRAhjMTzfBJXz+1Uz1eGd7NyUWrO5dL
    0d5vM1/6BGYQ5CgAt6c4FRRkH/2CBavZwz/4gnbR29HHlvrt0TTE7ECOVKZsGzLx
    SersmBRVDjfLmFewcV2evXjux/2I4fJUXdVhrx6hRUj+oxKS34T5ta08gdBdgygv
    U9U0CtMlrzsx2TPpO+RolqlcoQom38H/JNd0u87bG8x7DhLIQPzrHPN0i+NSHu2l
    QL6F593/IoL/ox72MFrZuOnpOOpbmW467ymCsEPMe/oYL3yQv8O05UphX6pp8RoN
    zNLGBt49DWkA+LPSpJCDEQndy/fXlxBAzTBGbHLLXRnoGO2b8R5zU3heuJNW5saX
    +s6CEr0jl1YJ0+XROyK6UvaQBSV5Fj0SdPoTlTQi2m6BsucqIDct4DnWohH7b8bR
    j42RbWID9v8PjVcSMf5LKOJ52e/F/VBh28YQxhbfWFhStcuildWmTIsbus7iWRKO
    nURX9canr89DneQKvXrJCPMPbKATbAfCyIgWdlEgcgotCAYyzSqgZTBM4D/mBg0b
    EIzgPe/IeXX5Xo64t/dpkwTLaYCJKpFmEbnvFFBRgWaVQv2IWFwSpoumElJ4PTS0
    huNI5V6zoWCc7ETWkFVSbtkCAwEAAQ==
    -----END PUBLIC KEY-----`,
    
    priv_key:`-----BEGIN PRIVATE KEY-----
    MIIJQwIBADANBgkqhkiG9w0BAQEFAASCCS0wggkpAgEAAoICAQC0+T28tGyNwaof
    pLl8AjFr3el4ARebZGI7+mgyNUDMLTRYxpGWyGSIJECGMxPN8ElfP7VTPV4Z3s3J
    Ras7l0vR3m8zX/oEZhDkKAC3pzgVFGQf/YIFq9nDP/iCdtHb0ceW+u3RNMTsQI5U
    pmwbMvFJ6uyYFFUON8uYV7BxXZ69eO7H/Yjh8lRd1WGvHqFFSP6jEpLfhPm1rTyB
    0F2DKC9T1TQK0yWvOzHZM+k75GiWqVyhCibfwf8k13S7ztsbzHsOEshA/Osc83SL
    41Ie7aVAvoXn3f8igv+jHvYwWtm46ek46luZbjrvKYKwQ8x7+hgvfJC/w7TlSmFf
    qmnxGg3M0sYG3j0NaQD4s9KkkIMRCd3L99eXEEDNMEZscstdGegY7ZvxHnNTeF64
    k1bmxpf6zoISvSOXVgnT5dE7IrpS9pAFJXkWPRJ0+hOVNCLaboGy5yogNy3gOdai
    EftvxtGPjZFtYgP2/w+NVxIx/kso4nnZ78X9UGHbxhDGFt9YWFK1y6KV1aZMixu6
    zuJZEo6dRFf1xqevz0Od5Aq9eskI8w9soBNsB8LIiBZ2USByCi0IBjLNKqBlMEzg
    P+YGDRsQjOA978h5dflejri392mTBMtpgIkqkWYRue8UUFGBZpVC/YhYXBKmi6YS
    Ung9NLSG40jlXrOhYJzsRNaQVVJu2QIDAQABAoICAAlu6wAKvxRSDZw95cHAKwOS
    gwyTcP+zdzflZPhGBJlH+3ArtJPRoQTkL91W7P+5eFefzmO3AZBTZ/JngGYYLDZ1
    s+HvjSycxeFgsO3hyGd340K0IAqL40tdSqbBSw3cZN0auhoDOEcQ0+J3dM/jo0Zt
    OZUxTSAzOhh4LK0yGjfJ5Zd04KVXcHyeJmv+ip0+EXDeY1vFlo8Y5zwkiHVRW0sx
    RDfSuwaD7USjQzNxI98Dl72Ytf9IoVQyK2ZtQUhVC66Hfdr+XAKLsa3nhOSyEkC6
    VahJDvclcS4Xa3GIkMwF3FdwRuNL5sRH64Uk2ZQ2THuwMGrgiI3Bp2r5SvGrgB/Q
    GKhmuoVesn00xduIzeup0as90idgUcGEdfV2Xpv16yaJM0lVul0Uocu6d3WDUlnH
    FbXwMD38M+XohaCkex9R1OOWYt+FK2klQTsjXGjSEe4aMgL3fn0RwbV7KNpl+Ffb
    7SadsX/KE7aBIs7mDKn6aRqsxl0BPoLO99BP3qbWsriNQdz/Y8NF3JATL2vIABsB
    UeNiE4cbwGRq6tdeu3EeJWhteXLfPPK2sOHEagrKfCV1jTntskdt0b1TM6EppnNj
    gjlTuTl3mYkklfZBGblZliNvh07cOq11aH84YalzpQ8yEQNshNoZUXs6LtloW02A
    qd5JWgYBVqK9VDRyyRHRAoIBAQC95NC0s/CpV0R4jOjVwkE7ukQ6Zw1uDvsyLKIy
    sn6sqwXFLbAu9IVlwpjF6O4qA6c1Nk9J3hq5i6f3Wm1ld4+1LMZBmtqCyKUtxiVd
    8FGxFRATE1Np51E5atNXBoXa3ZQRrax5IvqDLLLqk42KchcEfR0HTSWnEy1zutB3
    dqWPORcw1nBcUiwDFsiSUkuFFMN6G6qx4i6IyH5LDms9Zs5P6Ka9perW3QJyLuc5
    BiAQqj604NyKJ2pyvmt/ihnHzFf6zsUpZ68Y7AvFdF6A5DcBLtGVRe1LyHNDXD0r
    pgPec41geHL0M7boZvYiqZ/AjVarhTtA1NEtPB+MlQS/sZGJAoIBAQDz+Xa6/1lN
    MfpawrepS7tWn8YqKi76fZh1/FhKc7ndKRtpkNNrv2EHIBoMy4FrWHPTyVcdfZWI
    VW8hIRu5QB4Jqhs1QSZp7lzlLpkmLnlN2ZWCEckjiI5dcjAHgnf4zkEQJVJeNwIB
    RHueci5lstSK/u7OvxhhsV8MIxx+JfWOzXtY/43RBl2w046YqaWjJEn/YAO0Zcte
    Z7Odpl+EZJKgo5sho3GlXaRQtJz2tpIewpduXRkSSfb6Do2PfjoXpIEQEbpGePs8
    mBBHQltZujRNKjdxDowKAiw0DNJWaPBEntThsx0EaBCp3Pvba5092B6s4mvV4yIL
    S0lTpUb6lC7RAoIBAQCwYT196vkpwZYmxvNpwUIoQS4odRnjVWT7SLvrjSHeLghx
    CqvwIU1apLKUeXPmcHg6E9T3zqZSG6cLOOIN8kz4Qrtvqm82NTo/1yFULOkkK2U/
    4p6C6snmJBRja/qevVtQRFnENrorZzRUcHNy2eAJWlDBPGYx1r1MFoSVwg2rh6LD
    yRqalfStddcdGfVtiki7PcGB8o24nUm5W44+fSQ60uU1mEwWATjJaEl7DPZxpEAU
    cBxI8q2MINTmpRanH2XE35QAjn70DiQs5pb074UrwxoGpenwjyKIub9TsKM9V2VE
    6vwTWYmWOjR7I8Tv7BhJvrm30TTt6D+Bq9S6XSvJAoIBAQDbsuGAM0GvijnwUTgi
    c9gXgXlpruLvHvqUBNFc+il9Ru8weQJqHMS7LeVHcFry14w2QzD3lYd6ZbTVGINm
    pp5mBZDKvAhjjAqLNxMilucVe8JuhqWiIe5Rp+ooWQzAOLvOVk1+GfrsOUc4v4mY
    exl6sWdLNjo2fwsgWt3lmzkth0x0MGz8tcbY3Smjv+EwsBUSqturL5+bO7c7V/7b
    sdV4Ytvnxs8oItM8+ef/IxkiKglDkQJqvcByHXjMptaZQ8IVPmGH5JoZTrYZ9V9d
    xZgjhMi4PmlM+ffkR0eCGK2EnaMGO8z9ktiunhkO1jGa52t4M8z3prmZggpeEI+C
    Rr6BAoIBAA5yVY8mGYK6M2Lzx16OWugXhL3okf23phs3MoL0bW93uCyORta15ufE
    09nT3EYCo/TqqAyhG+Dd5kf304FkqUbE1LZY80yhZLtODR1A3Kw9v6S400w9yfXd
    8Fh9pt+6EK15ariLdWV4h6LEfP790OyQpqxe2H+XmxHd6tAjl4Ms/sI40IUTP0gz
    G1jiuLQIAY1YI8X5TCjeGyApTy3v4t7epPytNBNLAbT/j1WmFvdj7zdOniLM3KaV
    313LIPdXjq3z5bsiwWSbx0VccgYXVqUqSJEDpuhz2KNjl9mJ/5FrxXZSlLc43vGz
    SZt2B+YZ5jJWPsc6DJ3xtvJJ8N6WyIc=
    -----END PRIVATE KEY-----`,
  bot:'https://devchatbot.elabossacademy.com/'
  
  };