import { Injectable } from '@angular/core';
import { FacebookPixelService } from './facebookPixel.service';
import { environment } from 'src/environments/environment';
import { ISearchParams } from '../interfaces/types';


@Injectable({
  providedIn: 'root',
})
export class PathValidationService {
  constructor(private px: FacebookPixelService) {}

  // _setCookie(product: string, xdays: number) {
  //   const cookieName = '__' + product + '_access_time';
  //   let msdate: number;
  //   // const today =
  //   //   new Date().getTime() -
  //   //   new Date(new Date(new Date().getTime())).getTimezoneOffset() * 1000;
  //   // const divider = 100;

  //   // if (!(getCookie(cookieName) && getCookie(cookieName) != null)) {
  //   if (getCookie(cookieName) == undefined) {
  //     const HOURS=24
  //     const MINUTES=60
  //     const SECONDS=60
  //     const MILISECONDS=1000
  //     const _xdays = xdays * HOURS * MINUTES * SECONDS * MILISECONDS; // = 259200000 
  //     // msdate = Date.parse(params.get('date')!) + _3days;
  //     msdate = Date.parse(new Date().toISOString());
  //     msdate = msdate + _xdays;

  //     if (!Number.isNaN(msdate)) {
  //       const cookie = setCookie(
  //         cookieName,
  //         new Date(msdate).toISOString().replace(/"/g, ''),
  //         { expires: 365, path: window.location.pathname, domain: environment.domain }
  //       );
  //     }
  //   }
  // }

  getLocalStorate(key:string){
    return localStorage.getItem(key)
  }

  getSearchParams(params:string){
    let searchParams:ISearchParams={}
    
    const elements=params.replace('?','').split('&')

    elements.forEach((element:string)=>{
      const key=element.split('=')[0]
      const value=element.split('=')[1]
      searchParams[key]=value
      

    })
    return searchParams
  }

  setLocalStorage(product: string, xdays: number){
    const key = '__' + product + '_access_time';
    let msdate: number;
    // const today =
    //   new Date().getTime() -
    //   new Date(new Date(new Date().getTime())).getTimezoneOffset() * 1000;
    // const divider = 100;

    // if (!(getCookie(cookieName) && getCookie(cookieName) != null)) {
    if (this.getLocalStorate(key) == null) {
      const HOURS=24
      const MINUTES=60
      const SECONDS=60
      const MILISECONDS=1000
      const _xdays = xdays * HOURS * MINUTES * SECONDS * MILISECONDS; // = 259200000 
      // msdate = Date.parse(params.get('date')!) + _3days;
      msdate = Date.parse(new Date().toISOString());
      msdate = msdate + _xdays;

      if (!Number.isNaN(msdate)) {
        const data={
          "value":new Date(msdate).toISOString().replace(/"/g, ''),
          "domain":environment.domain,
          "path":window.location.pathname,
          "expires":365
        }
        
        localStorage.setItem(key,JSON.stringify(data))
        // const cookie = setCookie(
        //   key,
        //   new Date(msdate).toISOString().replace(/"/g, ''),
        //   { expires: 365, path: window.location.pathname, domain: environment.domain }
        // );
      }
    }

  }

  pathValidator(product: string) {
    const params = new URLSearchParams(window.location.search);
    if (params.toString().length > 0) {
      params.forEach((value: any, key: any) => {
        sessionStorage.setItem(key, value);
      });
      if (product == 'ms') {
        console.log('before _setCookie');

        this.setLocalStorage(product, 100);
      }
      window.location.replace(
        window.location.origin + window.location.pathname
      );
    } else {
      const key = '__' + product + '_access_time';
      if (product == 'ms') {
        if (this.getLocalStorate(key) == null) {
          window.location.replace(window.location.origin);
        } else {
          this.px.load();
          this.px.pageView(product.toUpperCase());
        }
      } else {
        this.px.load();
        this.px.pageView(product.toUpperCase());
      }
    }


  }
}
