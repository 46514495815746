import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  OnInit,
} from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalService } from '../../../services/modal.service';
import { StripeService } from '../../../services/stripe.service';
import { UsersCRUDService } from '../../../services/usersCRUD.service';
import { CheckoutComponent } from '../../buyingProcess/checkouts/checkout/checkout.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DefaultValueAccessor } from '@angular/forms';
import { EnrolledComponent } from '../../buyingProcess/enrolled/enrolled.component';
import { WaitingComponent } from '../../waiting/waiting.component';
import countries from '../../../data';
import { CryptographyService } from '../../../services/cryptography.service';
import { CompleteComponent } from '../../buyingProcess/complete/pay-resource/complete.component';
import { FreeResourceComponent } from '../../buyingProcess/complete/free-resource/free-resource.component';
import { UsefullFunctionsService } from '../../../services/usefull-functions.service';

@Component({
  selector: 'app-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.css'],
})
export class FormComponent implements OnInit, AfterViewInit {
  public countries = countries;
  // public searchbox!:string
  searchBox!: string;
  // search!: HTMLInputElement

  constructor(
    private inject: Injector,
    @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private usersCRUD: UsersCRUDService,
    private stripe: StripeService,
    private dialog: MatDialog,
    private modal: ModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.politica = window.location.origin + '/politica_de_privacidad';
    this.tyc =
      document.location.pathname.split('/')[1] + '/terminos_y_condiciones';
    switch (document.location.pathname.split('/')[1]) {
      case 'nms':
        {
          this.contrato =
            'https://drive.google.com/file/d/1GXu4fPTcscFUECEfZjHfqcwccu8kOYCF/view?usp=sharing';
        }
        break;
      case 'ssp':
        {
          this.contrato =
            'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
        }
        break;
      case 'mci':
        {
          this.contrato =
            'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
        }
        break;
      case 'jhts':
        {
          this.contrato =
            'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
        }
        break;
    }
    // if (document.location.pathname.split('/')[1] == 'nms') {
    //   this.contrato =
    //     'https://drive.google.com/file/d/1GXu4fPTcscFUECEfZjHfqcwccu8kOYCF/view?usp=sharing';
    // } else {
    //   if (document.location.pathname.split('/')[1] == 'ssp') {
    //     this.contrato =
    //       'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
    //   } else {
    //     if (document.location.pathname.split('/')[1] == 'mci') {
    //       this.contrato =
    //         'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
    //     } else {
    //       if (document.location.pathname.split('/')[1] == 'jhts') {
    //         this.contrato =
    //           'https://drive.google.com/file/d/15-l2YGgjkmZkAnq-FDJXv8rnZuU0n4YI/view?usp=sharing';
    //       }
    //     }
    //   }
    // }
  }

  tyc!: string;
  contrato!: string;
  politica!: string;

  acepto =
    'Acepto los términos y condiciones y el contrato de prestación de servicios';
  hide: boolean = true;

  // dataFormGroup = new FormGroup({
  //   first_name: new FormControl('', [Validators.required]),
  //   last_name: new FormControl('', [Validators.required]),
  //   country: new FormControl('', [Validators.required]),

  //   mobile: new FormControl(
  //     '',
  //     Validators.compose([
  //       Validators.required,
  //       Validators.pattern(/[1-9]\d{7,14}/),
  //       Validators.pattern(/^[+]/),
  //       Validators.maxLength(15),
  //       Validators.minLength(8),
  //     ])
  //   ),
  //   email: new FormControl('', [
  //     Validators.required,
  //     Validators.pattern(/^[A-Za-z0-9._%+-]+@gmail.com/),
  //   ]),
  //   accepts_c: new FormControl(),
  //   id: new FormControl(),
  //   manychat_id: new FormControl(),
  //   coupon: new FormControl(''),
  // });

  dataFormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Za-z0-9._%+-]+@gmail.com/),
    ]),
    // ig_account: new FormControl('', [Validators.required]),
    accepts_c: new FormControl(),
  });

  ngOnInit() {
    const usefull = this.inject.get(UsefullFunctionsService);
    const entity_id = usefull.getTrackingCookie('entity');
    const usersCRUD = this.inject.get(UsersCRUDService);
    usersCRUD.getEntity(entity_id).subscribe({
      next: (value) => {
        
        const info: any = value.body;

        if ('personal_data' in info) {
          this.dataFormGroup.patchValue(info['personal_data']);
          this.dataFormGroup.markAllAsTouched();
        }
        if ('enrollements' in info) {
          for (let i = 0; i < info['enrollements'].length; i++) {
            if (
              info['enrollements'][i].product_name.toLowerCase() ==
                document.location.pathname.split('/')[1] &&
              info['enrollements'][i].active == true
            ) {
              const dialog = this.inject.get(MatDialog);
              dialog.closeAll();
              this.openSnackBar(
                `ya se encuentra inscrito a ${info['enrollements'][i].product_name} `,
                'ok'
              );
              break;
            }
          }
        }
      },
    });

    if (this.matDialogdata != undefined) {
      const img = document.querySelector('#banner') as HTMLImageElement;
      
      if (('img' in this.matDialogdata)&& img!=null ){
        img.src = this.matDialogdata.img;
        // console.log(img.src);
        
      } 
      const tyc = document.querySelector('#tyc') as HTMLAnchorElement;
      tyc.href = this.matDialogdata.tyc;

      if ('contrato' in this.matDialogdata) {
        const contrato = document.querySelector(
          '#contrato'
        ) as HTMLAnchorElement;
        contrato.href = this.matDialogdata.contrato;
      }

      if (this.matDialogdata.direct) {
        const contrato = document.querySelector(
          '#contrato'
        ) as HTMLAnchorElement;
        this.acepto = 'Acepto los términos y condiciones';
        contrato.remove();
      }
    }

    const original = DefaultValueAccessor.prototype.registerOnChange;

    DefaultValueAccessor.prototype.registerOnChange = function (fn) {
      return original.call(this, (value) => {
        const trimmed = value.trim();
        return fn(trimmed);
      });
    };

    let info: any = {};

    for (let index = 0; index < sessionStorage.length; index++) {
      const key = sessionStorage.key(index)!;
      const value = sessionStorage.getItem(key);
      info[key] = value;
    }

    if (Object.keys(info).length > 0) {
      // this.fillform(info);
    }

    for (let index = 0; index < sessionStorage.length; index++) {
      const key = sessionStorage.key(index)!;
      if (key != 'code') {
        sessionStorage.removeItem(key);
      }
    }

    //  const _data=sessionStorage.getItem('data')
    //  if(_data!=undefined){
    //   const json_data=JSON.parse(_data.replace('%40','@'))
    //   this.fillform(json_data)

    //  }

    // const select = document.querySelector('#select') as HTMLElement;
    // select.onclick = this.onclick;
  }
  ngAfterViewInit(): void {

    // if (this.matDialogdata.direct) {
    //   console.log('eliminando');
    //   const contrato = document.querySelector('#contrato') as HTMLAnchorElement;
    //   this.acepto = 'Acepto los términos y condiciones';
    //   contrato.remove();
    // }
  }
  // fillform(json_data: any) {
  //   this.dataFormGroup.patchValue(json_data);
  //   if (this.dataFormGroup.controls['email'].value === 'ALL') {
  //     this.dataFormGroup.controls['email'].setValue('');
  //   }

  //   this.dataFormGroup.markAllAsTouched();

  //   if ('email' in json_data) {
  //     if (this.dataFormGroup.controls['email'].errors != null) {
  //       const acv: string =
  //         this.dataFormGroup.controls['email'].errors['pattern'].actualValue;
  //       if (acv.split('@')[1] != 'gmail.com') {
  //       }
  //     }
  //   }
  // }
  // trackByIndex(index: number, obj: any) {
  //   console.log('index',index);
  //   console.log('obj',obj);

  //   return index;
  // }
  // onclick(ev: MouseEvent) {
  //   // ev.preventDefault()
  //   document.onkeydown=(ev)=>{
  //     const elmnt=ev.target as HTMLElement
  //     console.log('searchbox',this.searchBox);
  //     const search=document.querySelector('#searchbox')as HTMLInputElement
  //     console.log('search?.innerHTML',search?.innerHTML);

  //     if(elmnt.localName==='mat-select'){
  //       // console.log(this.searchbox);

  //       this.searchBox=this.searchBox+ev.key
  //       // console.log(this.searchbox);

  //     }

  //   }
  // }
  openSnackBar(message: string, action: string) {
    const snack = this.inject.get(MatSnackBar);
    snack.open(message, action, {
      duration: 3000,
    });
  }
  validate_enrrol(enrollements: any, product: string) {
    // validar que esta funcion si este trabajando y que todos los valores estén llegando de manera correcta
    let _name: any = [];
    for (const _product of enrollements) {
      _name.push(_product['name']);
    }

    if (product == 'SSP') {
      if (_name.includes('IBS')) {
        return 'IBS';
      }
      if (_name.includes('NMS')) {
        return 'NMS';
      }
      if (_name.includes('SSP')) {
        return 'SSP';
      }
    } else {
      if (product == 'NMS') {
        if (_name.includes('IBS')) {
          return 'IBS';
        }
        if (_name.includes('NMS')) {
          return 'NMS';
        }
      } else {
        if (product == 'IBS') {
          if (_name.includes('IBS')) {
            return 'IBS';
          }
        } else {
          if (product == 'MCI') {
            if (_name.includes('MCI')) {
              return 'MCI';
            }
          }
        }
      }
    }
    return '';
  }

  getErrors(dataFormControl: FormControl) {
    if (
      'minlength' in dataFormControl.errors! ||
      'maxlength' in dataFormControl.errors!
    ) {
      return 'Ingresa tu número en formato intl.';
    }
    if ('pattern' in dataFormControl.errors!) {
      return 'No olvides el signo "+".';
    }
    if ('required' in dataFormControl.errors!) {
      return 'Ingresa un número';
    }
    return null;
  }
  writeData(data: FormGroup) {
    let validaEmail = true;
    if (sessionStorage.getItem('code') != null) {
      let encodedEncryptedData = sessionStorage.getItem('code');

      let cryptographyService = this.inject.get(CryptographyService);
      const message = cryptographyService.getmessage(encodedEncryptedData!);

      if (message['user'] != data.value.email && message['user'] != 'ALL') {
        validaEmail = false;
      }
    }
    if (validaEmail) {
      data.value.email = data.value.email.toLowerCase();
      // window.open(this.matDialogdata.link)

      if (data.status != 'INVALID') {
        const options = {
          disableClose: true,
          autoFocus: true,
          width: 'auto',
          height: 'auto',
          data: this.matDialogdata,
        };
        this.modal.modalForm(this.dialog, WaitingComponent, options);

        // this.usersCRUD.createForCheckout(data.value).subscribe({
        let user_id = 0;

        const usefull = this.inject.get(UsefullFunctionsService);
        const entity_id = usefull.getTrackingCookie('entity');
        const usersCRUD = this.inject.get(UsersCRUDService);
        usersCRUD.getEntity(entity_id).subscribe({
          next: (value) => {
            const info: any = value.body;

            let enrolled = false;
            if ('enrollements' in info) {
              for (let i = 0; i < info['enrollements'].length; i++) {
                if (
                  info['enrollements'][i].product_name.toLowerCase() ==
                    document.location.pathname.split('/')[1] &&
                  info['enrollements'][i].active == true
                ) {
                  const dialog = this.inject.get(MatDialog);
                  dialog.closeAll();
                  this.openSnackBar(
                    `ya se encuentra inscrito a ${info['enrollements'][i].product_name} `,
                    'ok'
                  );
                  enrolled = true;
                  break;
                }
              }
            }
            if (!enrolled) {
              usersCRUD.setEntity({ personal_data: data.value }).subscribe({
                next: (value) => {},
                complete: () => {
                  this.stripe.getPublishableKey().subscribe({
                    next: (v) => {
                      const { public_key } = v.body as any;
                      const { email } = data.value;

                      this.matDialogdata.publishableKey = public_key;
                      this.matDialogdata.email = email;
                      this.matDialogdata.src = environment.src;
                      this.matDialogdata.name = data.controls['name'].value;
                      // this.matDialogdata.mobile = data.controls['mobile'].value;
                      // this.matDialogdata.city = data.controls['country'].value;
                    },
                    complete: () => {
                      // if (!enrolled.state) {
                      if (true) {
                        this.modal.close(this.dialog);
                        const options = {
                          disableClose: false,
                          autoFocus: true,
                          width: 'auto',
                          height: '650px',
                          data: this.matDialogdata,
                        };
                        this.modal.modalForm(
                          this.dialog,
                          // this.matDialogdata,
                          CheckoutComponent,
                          options
                        );
                      }
                    },
                    error: (e) => console.error(),
                  });
                },
              });
            }
          },
        });
        // this.usersCRUD.createUser(data.value).subscribe({
        //   next: (v) => {
        //     user_id = (v.body as any)['id']['id'];
        //   },
        //   complete: () => {
        //     const { email } = data.value;
        //     let enrolled: { product: string; state: boolean };
        //     let productEnrolled = '';
        //     let couponState: string;
        //     let price: number;

        //     if (this.matDialogdata.direct) {
        //       this.usersCRUD.getProducts('Masterclass Serie').subscribe({
        //         next: (v) => {
        //           let product_id = (v.body as any)['products'][0]['id'];
        //           // let product_id = v.body as any[0]['id']
        //           // (v.body as any)['products'][0]
        //           let data_to_enroll = { product: product_id, user: user_id };

        //           this.usersCRUD.enroll_mc(data_to_enroll).subscribe({
        //             complete: () => {
        //               window.location.replace(
        //                 window.location.origin +
        //                   window.location.pathname +
        //                   '/enrollement?email='+data.value.email
        //               );
        //             },
        //             error:(e) =>console.error(e)

        //           });
        //         },
        //       });
        //     } else {
        //       this.usersCRUD
        //         .isenrolled(email, this.matDialogdata.product)
        //         .subscribe({
        //           next: (v) => {
        //             if (Object.keys(v.body as any).includes('enrolls')) {
        //               // if ((v.body as any).length > 0) {
        //               const _product = this.validate_enrrol(
        //                 (v.body as any)['enrolls'],
        //                 this.matDialogdata.product
        //               );

        //               if (_product.length > 0) {
        //                 enrolled = { product: _product, state: true };
        //               } else {
        //                 enrolled = { product: _product, state: false };
        //               }
        //             } else {
        //               enrolled = { product: '', state: false };
        //             }
        //           },
        //           complete: () => {
        //             if (!enrolled.state) {
        //               if (data.controls['coupon'].value.length > 0) {
        //                 const dataCheckCupon = {
        //                   email: data.controls['email'].value,
        //                   to_buy: this.matDialogdata.product,
        //                   cupon: data.controls['coupon'].value,
        //                 };
        //                 this.usersCRUD.checkCupon(dataCheckCupon).subscribe({
        //                   next: (v) => {
        //                     couponState = (v.body as any)['state'];

        //                     // if ('valid' in (v.body as any)) {
        //                     if (couponState) {
        //                       price = (v.body as any)['price'];
        //                     }
        //                   },
        //                   complete: () => {
        //                     this.stripe.getPublishableKey().subscribe({
        //                       next: (v) => {
        //                         const { publishablekey } = (v.body as any)[0];
        //                         const { email } = data.value;

        //                         this.matDialogdata.publishableKey =
        //                           publishablekey;
        //                         this.matDialogdata.email = email;
        //                         this.matDialogdata.src = environment.src;
        //                         this.matDialogdata.fullname =
        //                           data.controls['first_name'].value +
        //                           ' ' +
        //                           data.controls['last_name'].value;
        //                         this.matDialogdata.mobile =
        //                           data.controls['mobile'].value;
        //                         this.matDialogdata.city =
        //                           data.controls['country'].value;

        //                         if (couponState == 'valid') {
        //                           this.matDialogdata.price = price;
        //                         }
        //                         this.matDialogdata.couponState = couponState;
        //                       },
        //                       complete: () => {
        //                         if (!enrolled.state) {
        //                           this.modal.close(this.dialog);
        //                           const options = {
        //                             disableClose: false,
        //                             autoFocus: true,
        //                             width: 'auto',
        //                             height: '650px',
        //                             data: this.matDialogdata,
        //                           };
        //                           this.modal.modalForm(
        //                             this.dialog,
        //                             // this.matDialogdata,
        //                             CheckoutComponent,
        //                             options
        //                           );
        //                         }
        //                       },
        //                       error: (e) => console.error(),
        //                     });
        //                   },
        //                 });
        //               } else {
        //                 this.stripe.getPublishableKey().subscribe({
        //                   next: (v) => {
        //                     const { publishablekey } = v.body as any;
        //                     const { email } = data.value;

        //                     this.matDialogdata.publishableKey = publishablekey;
        //                     this.matDialogdata.email = email;
        //                     this.matDialogdata.src = environment.src;
        //                     this.matDialogdata.fullname =
        //                       data.controls['first_name'].value +
        //                       ' ' +
        //                       data.controls['last_name'].value;
        //                     this.matDialogdata.mobile =
        //                       data.controls['mobile'].value;
        //                     this.matDialogdata.city =
        //                       data.controls['country'].value;
        //                   },
        //                   complete: () => {
        //                     if (!enrolled.state) {
        //                       // if (true) {
        //                       this.modal.close(this.dialog);
        //                       const options = {
        //                         disableClose: false,
        //                         autoFocus: true,
        //                         width: 'auto',
        //                         height: '650px',
        //                         data: this.matDialogdata,
        //                       };

        //                       this.modal.modalForm(
        //                         this.dialog,
        //                         // this.matDialogdata,
        //                         CheckoutComponent,
        //                         options
        //                       );
        //                     }
        //                   },
        //                   error: (e) => console.error(),
        //                 });
        //               }
        //             } else {
        //               this.modal.close(this.dialog);
        //               this.matDialogdata.previous = window.location.pathname;
        //               this.matDialogdata.name = data.value['first_name'];
        //               this.matDialogdata.email = data.value['email'];
        //               this.matDialogdata.enrolled = enrolled.product;

        //               const options = {
        //                 disableClose: true,
        //                 autoFocus: true,
        //                 width: 'auto',
        //                 height: 'auto',
        //                 data: this.matDialogdata,
        //               };

        //               this.modal.modalForm(
        //                 this.dialog,
        //                 // this.matDialogdata,
        //                 EnrolledComponent,
        //                 options
        //               );
        //             }
        //           },
        //         });
        //     }
        //   },
        //   error: (e) => console.error(e),
        //   // complete: () => {},
        // });
      } else {
        this.openSnackBar('Faltan campos por diligenciar', 'ok');
      }
    } else {
      console.log(
        'Enlace valido solo para el correo: ',
        sessionStorage.getItem('email')
      );
    }
  }
}
