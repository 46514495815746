import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { NmsComponent } from './nms/nms.component';
import { CompleteComponent } from './shared/components/buyingProcess/complete/pay-resource/complete.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },

  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./products/products.module').then((m) => m.ProductsModule),
  // },
  {
    path: '',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
  },

  // {path:'',redirectTo:'p',pathMatch:'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
