<div class="form">

  <h1
    class="font-century text-align-center font-size-x_xrem container c-4E4E4E"
  >
    Datos de contacto
  </h1>

  <picture class="cont_Centrado text-align-justify">
    <img class="container-100-center" id="banner" />
  </picture>
  <!-- <h1
    class="font-century text-align-center font-size-x_xrem container c-4E4E4E"
  >
    Ingresa tus datos para ver esta Mini-Serie valorada en $37 usd, pero que hoy
    te la vas poder llevar gratis.
  </h1> -->

  <!-- <mat-form-field [formGroup]="dataFormGroup" class=""> -->
  <mat-form-field [formGroup]="dataFormGroup" class="">
    <mat-label appearence="" class="form-label">Nombres:</mat-label>
    <input
      class="input"
      matInput
      placeholder="Nombres"
      required
      formControlName="name"
    />
    <mat-error *ngIf="dataFormGroup.controls['name'].invalid"
      >Ingresa tu nombre</mat-error
    >
  </mat-form-field>
  <mat-form-field [formGroup]="dataFormGroup" class="">
    <mat-label appearence="" class="form-label">Correo de gmail:</mat-label>
    <input
      class="input"
      matInput
      placeholder="correo@gmail.com"
      required
      formControlName="email"
    />
    <mat-error *ngIf="dataFormGroup.controls['email'].invalid"
      >Ingresa un correo de gmail.</mat-error
    >
  </mat-form-field>
  <!-- <p class="ig-disclaimer">
    <strong>*esta info para darle una mirada a lo que estas haciendo y ver si mi Mini
      Serie sí te puede ayudar.</strong>
  </p> -->
  <!-- <mat-form-field [formGroup]="dataFormGroup" class="">
    <mat-label appearence="" class="form-label">Especialidad:</mat-label>
    <mat-error *ngIf="dataFormGroup.controls['speciality'].invalid"
      >Ingresa una especialidad</mat-error
    >
    <mat-select formControlName="speciality" id="select">
      <mat-option
        *ngFor="let speciality of specialities | filter : searchBox"
        [value]="speciality"
      >
        {{ speciality }}
      </mat-option>
      
    </mat-select>
  </mat-form-field> -->

<!-- 
  <div class="mobile-form">
    <mat-form-field [formGroup]="dataFormGroup" class="">
      <mat-label appearence="" class="form-label">País:</mat-label>
      <mat-error *ngIf="dataFormGroup.controls['country'].invalid"
        >Ingresa un país</mat-error
      >
      <mat-select formControlName="country" id="select">
        <div class="flex-search">
          <input
          class="mat-select-input"
          matInput
          [(ngModel)]="searchBox"
          placeholder="buscar..."
          formControlName="country"
        />
        <span class="material-symbols-outlined" (click)="clearSearchBox()">search</span>          
        </div>



        <mat-option
          *ngFor="let country of countries | filter : searchBox"
          [value]="country.dial_code"
        >
          {{ country.flag }}
          {{ country.dial_code }}
          {{ country.code }}
        </mat-option>
        
      </mat-select>
    </mat-form-field>
    <mat-form-field [formGroup]="dataFormGroup" class="">
      <mat-label appearence="" class="">Contacto:</mat-label>
      <input
        class=""
        matInput
        placeholder="321456789"
        required
        formControlName="mobile"
      />
      <mat-error
        class="break-word"
        *ngIf="dataFormGroup.controls.mobile.invalid"
        >{{ getErrors(dataFormGroup.controls.mobile) }}</mat-error
      >
    </mat-form-field>
  </div>
  <p class="whatsapp-disclaimer"><strong>*tu Whatsapp es para poderte compartir el enlace de la Mini-Serie.</strong></p> -->

  <!-- <form [formGroup]="dataFormGroup" class="form"> -->
  <form [formGroup]="dataFormGroup" class="padding-top-x-vw">
    <mat-checkbox class="checkbox_margin" required formControlName="accepts_c"
      ><span id="acepto">
        {{ acepto }}
      </span>

      <a id="tyc" target="”_blank”" href="{{ tyc }}">ver aquí TyC</a>
      &nbsp;

      <a id="politica" target="”blank”" href="{{ politica }}"
        >ver aquí politica de privacidad de datos</a
      >
    </mat-checkbox>
    <mat-error
      class="error-accept"
      *ngIf="dataFormGroup.controls['accepts_c'].invalid"
      >Debes aceptar los terminos y condiciones para continuar</mat-error
    >
  </form>

  <!-- </form> -->

  <!--******************************************************* disclaimer ***************************************************************-->
</div>
<!-- </form> -->
<div class="checkout-button">
  <button
    [disabled]="dataFormGroup.invalid"
    (click)="writeData(dataFormGroup)"
    mat-raised-button
  >
    Continuar
  </button>
</div>
