import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
@Injectable()
export class StripeService {
    constructor(
        private httpClient: HttpClient,
    
    ) { }
  urlbot = environment['bot'];

  getPublishableKey() {
    // const baseUrl = environment['host'] + '/config';
    // this.urlbot
    const baseUrl = this.urlbot + '/config';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })
    
    return this.httpClient.get(baseUrl, { headers: headers, observe: 'response' })

  }

  _getPublishableKey():Promise<any>{
    const baseUrl = this.urlbot + '/config';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })

    // const baseUrl = environment['host'] + '/config';
    // this.urlbot

    return firstValueFrom(this.httpClient.get(baseUrl, { headers: headers, observe: 'response' }))

  }
  getClientSecret(){

    const baseUrl = environment['host'] + '/get_client_secret';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })

    return this.httpClient.get(baseUrl,{ headers: headers, observe: 'response' })


  }
  createPaymentIntent(data:any){

    // const baseUrl = environment['host'] + '/create_payment_intent';
    const baseUrl = this.urlbot + '/create_payment_intent';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })
    const body = JSON.stringify(data);

    
    return this.httpClient.post(baseUrl,body,{ headers: headers, observe: 'response' })


  }
  createSubscription(data:any){

    // const baseUrl = environment['host'] + '/create_payment_intent';
    const baseUrl = this.urlbot + '/create_subscription';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })
    const body = JSON.stringify(data);

    
    return this.httpClient.post(baseUrl,body,{ headers: headers, observe: 'response' })


  }
}
