import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoginAdminService } from '../services/login-admin.service';
import { UsersCRUDService } from '../services/usersCRUD.service';
import { ChangeInformService } from '../services/change-inform.service';
import { ModalService } from '../services/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { EnrolledComponent } from '../components/buyingProcess/enrolled/enrolled.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private inject: Injector,
    private router: Router,
    private changeInform:ChangeInformService) {}


  
  ctr = 0;

  intercept(request: HttpRequest<unknown>,next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x)));
      // .pipe(tap((x:HttpEvent<any>) => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
      if (err && err.status === 403 && this.ctr != 1) {    // if (false) {
      if (!(err.url?.search('login')! > -1)) {
        this.ctr++;
        let service = this.inject.get(LoginAdminService);
        let data: HttpResponse<any>
        service.refreshToken().subscribe({
          next: (x: any) => {

            if ('path' in err.error) {
              this.router.navigateByUrl(err.error['path']);
            }
            if('url'in err.error){
              let usersCrudService = this.inject.get(UsersCRUDService);
              if(err.error['method']=='GET'){
                usersCrudService.getEndpoint(err.error['url']).subscribe({
                  next:(v)=> {
  
                    let changeInform = this.inject.get(ChangeInformService);
  
                    changeInform.interceptorEvent.emit(v.body)
  
                  },
                  error:(err) => {
                    console.log('gggg',err);  
                  },
                  complete:() => {
  
                  },
                })
              }
              else if(err.error['method']=='POST'){
                usersCrudService.postEndpoint(err.error['url'],err.error['data']).subscribe({
                  next:(v:any)=>{
                    let modal = this.inject.get(ModalService);
                    let dialog = this.inject.get(MatDialog)
                    let snack= this.inject.get(MatSnackBar)

                    if(v.body.id >-1){
                      console.log('Interceptor',v.body);
                      
                      modal.close(dialog)
                      snack.open('Se inscribio con exito', 'Ok', {
                        duration: 3000,
                      });
                    }
                    else{      
                      snack.open('Ya inscrito', 'Ok', {
                        duration: 3000,
                      });

                      modal.close(dialog)
                    }
                    
                  },
                  error:(err) => {
                    console.log('postEndpoint',err);  
                  },
                  complete:() => {
  
                  },
                })
              }
                


            }


            this.ctr = 0;

            // return of(x);
          },
          complete: () => {

          },

          error: (err: any) => {
            service.revokeToken().subscribe({
              next: (x: any) => {
                this.router.navigateByUrl('/admin/auth');
                // return of(err.message);
                console.log('ppppppppp', x);

                return of('err.message__');
              },
              error: (err) => {
                return of(err.message);
              },
            });
          },
        });

        return of('Attempting to Refresh Tokens');
      } else {
        return of('response from /login');
      }
    } else {
      this.ctr = 0;
      return throwError(() => new Error('Non Authentication Error'));
    }
  }


}
