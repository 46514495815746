import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CryptographyService } from './cryptography.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginAdminService {
  constructor(
    private httpClient: HttpClient,
    private cryptographyService: CryptographyService
  ) {}
  
  baseUrl = environment['host'];
  headers = new HttpHeaders({
    'Content-type': 'application/json',
  });

  login(data: any) {
    const baseUrl = this.baseUrl + '/login';
    const body = {
      info: window.btoa(
        this.cryptographyService.encode(JSON.stringify(data))
      ),
    };

    return this.httpClient.post(baseUrl, body, {
      headers: this.headers,
      observe: 'response',
      withCredentials: true,
    });
  }

  refreshToken(): Observable<any> {
    const baseUrl = this.baseUrl + '/refreshtoken';
    return this.httpClient.post(
      baseUrl,
      {},
      { headers: this.headers, observe: 'response', withCredentials: true }
    );
  }

  revokeToken(): Observable<any> {
    const baseUrl = this.baseUrl + '/revoketoken';
    return this.httpClient.post(
      baseUrl,
      {},
      { headers: this.headers, observe: 'response', withCredentials: true }
    );
  }

  getSession(path: string): Observable<any> {
    const baseUrl = this.baseUrl + '/get_session';
    let options: HttpParamsOptions = { fromObject: { path: path } };
    const params = new HttpParams(options);

    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      params: params,
      withCredentials: true,
    });
  }
}
