import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CheckProductService {

constructor(private httpClient: HttpClient) {}
baseUrl = environment['host'];
headers = new HttpHeaders({
  'Content-type': 'application/json',
});

getProduct(course: any) {

    const baseUrl = this.baseUrl + '/get_product';
    let options: HttpParamsOptions = { fromObject: { course: course } };
    const params = new HttpParams(options);
    
    return this.httpClient.get(baseUrl, {
      headers: this.headers,
      observe: 'response',
      params: params,
    });
  }
}
