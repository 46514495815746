import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enrolled',
  templateUrl: './enrolled.component.html',
  styleUrls: ['./enrolled.component.css'],
})
export class EnrolledComponent implements OnInit, AfterContentInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {}
  ngAfterContentInit(): void {
    const txt = document.querySelector('#enroll') as HTMLParagraphElement;

    if (this.matDialogdata.product == this.matDialogdata.enrolled) {
      txt.innerHTML = `Hola <strong>${this.matDialogdata.name}</strong>,
      detectamos en nuestra base de datos, que el correo <strong>
      ${this.matDialogdata.email}</strong> ya se encuentra inscrito en
      <strong>${this.matDialogdata.product}</strong>`;
    } else {
      if (
        (this.matDialogdata.product == 'SSP'||'NMS' ) &&
        (this.matDialogdata.enrolled == 'NMS' ||'IBS')
      ) {
        txt.innerHTML = `Hola <strong>${this.matDialogdata.name}</strong>,
        detectamos en nuestra base de datos que el correo
        <strong>${this.matDialogdata.email}</strong>, se encuentra inscrito en programa 
        <strong>${this.matDialogdata.enrolled}</strong>, motivo por el cual no es posible
        adquirir este producto ya que <strong>${this.matDialogdata.product}</strong>
        hace parte de <strong>${this.matDialogdata.enrolled}</strong>`;
      }else{
        if(this.matDialogdata.product == 'SSP'||'NMS'){
          txt.innerHTML = 'toca revisar';
        }

      }
    }
  }
  close() {
    this.dialog
      .getDialogById(
        this.dialog.openDialogs[this.dialog.openDialogs.length - 1].id
      )
      ?.close();
    this.dialog
      .getDialogById(
        this.dialog.openDialogs[this.dialog.openDialogs.length - 2].id
      )
      ?.close();
    this.router.navigate([this.matDialogdata.previous]);
  }
}
