<div class="tucupampam" style="z-index: 100">
  <h1 style="margin-top: 10px"><strong>Términos y Condiciones</strong></h1>
  <p>Actualizado el 2024-04-07</p>
  <h1><strong>Términos generales</strong></h1>
  <p class="p8">
    Al acceder y realizar un pedido con
    <a><strong>Elaboss Academy</strong></a
    >, usted confirma que está de acuerdo y sujeto a los términos de servicio
    contenidos en los Términos y condiciones que se describen a continuación.
    Estos términos se aplican a todo el sitio web y a cualquier correo
    electrónico u otro tipo de comunicación entre usted y
    <a><strong>Elaboss Academy</strong></a
    >. <br /><br />
    Bajo ninguna circunstancia el equipo de
    <a><strong>Elaboss Academy</strong></a> será responsable de ningún daño
    directo, indirecto, especial, incidental o consecuente, que incluye, entre
    otros, la pérdida de datos o ganancias que surjan del uso o la incapacidad
    de usar, los materiales de este sitio, incluso si el equipo de
    <a><strong>Elaboss Academy</strong></a> o un representante autorizado han
    sido informados de la posibilidad de tales daños. Si su uso de materiales
    de este sitio resulta en la necesidad de servicio, reparación o corrección
    de equipos o datos, usted asume los costos de los mismos. <br /><br />
    <a><strong>Elaboss Academy</strong></a> no será responsable de ningún
    resultado que pueda ocurrir durante el curso del uso de nuestros recursos.
    Nos reservamos el derecho de cambiar los precios y revisar la política de
    uso de recursos en cualquier momento.
  </p>
  <h1><strong>Licencia</strong></h1>
  <p style="display: inline">
    <strong>Elaboss Academy </strong> le otorga una licencia revocable, no
    exclusiva, intransferible y limitada para descargar, instalar y usar la
    website estrictamente de acuerdo con los términos de este Acuerdo.
  </p>

  <p style="display: inline">
    Estos Términos y condiciones son un contrato entre usted y
    <strong>Elaboss Academy</strong> ("nosotros", "nuestro" o "nos") le otorga
    una licencia revocable, no exclusiva, intransferible y limitada para
    descargar, instalar y utilizar el sitio web estrictamente de acuerdo con los
    términos de este Acuerdo.
  </p>
  <p class="p6"><br /></p>
  <h1><strong>Definiciones y términos clave</strong></h1>
  <p class="p8">
    Para ayudar a explicar las cosas de la manera más clara posible en estos
    Términos y Condiciones, cada vez que se hace referencia a cualquiera de
    estos términos, se definen estrictamente como:
  </p>
  <ul class="ul1">
    <li class="li8">
      <p>
        <strong>Cookie:</strong> pequeña cantidad de datos generados por un
        sitio web y guardados por su navegador web. Se utiliza para identificar
        su navegador, proporcionar análisis, recordar información sobre usted,
        como su preferencia de idioma o información de inicio de sesión.<br />
      </p>
    </li>
    <li class="li8">
      <p>
        <strong>Compañía:</strong> cuando estos Terminos mencionan "Compañía",
        "nosotros", "nos" o "nuestro", se refiere a <a>Elaboss LLC</a>,
        <a
          >7345 West Sand Lake Road, STE 210 OFFICE 8408, Orlando, FL, US,
          32819</a
        >
        que es responsable de su información en virtud de estos Términos y
        Condiciones.<br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Plataforma:</strong> sitio web de Internet, aplicación web o
        aplicación digital de cara al público de
        <a><strong>Elaboss Academy</strong>.</a><br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>País:</strong> donde se encuentra
        <a><strong>Elaboss Academy</strong></a> o los propietarios / fundadores
        de <a><strong>Elaboss Academy</strong></a> en este caso es
        <a>Colombia</a>.<br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Dispositivo:</strong> cualquier dispositivo conectado a
        Internet, como un teléfono, tablet, computadora o cualquier otro
        dispositivo que se pueda usar para visitar
        <a><strong>Elaboss Academy</strong></a> y usar los servicios.<br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Servicio:</strong> se refiere al servicio brindado por
        <a><strong>Elaboss Academy</strong></a> como se describe en los términos
        relativos (si están disponibles) y en esta plataforma.<br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Terceros:</strong> se refiere a anunciantes, patrocinadores de
        concursos, socios promocionales y de marketing, y otros que brindan
        nuestro contenido o cuyos productos o servicios que creemos que pueden
        interesarle.<br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Sitio web:</strong> el sitio de
        <a><strong>Elaboss Academy</strong></a> al que se puede acceder a través
        de esta URL: <a>https://www.elabossacademy.com.</a><br />
      </p>
    </li>
    <li class="li8">
      <p class="s1">
        <strong>Usted:</strong> una persona o entidad que está registrada con
        <a><strong>Elaboss Academy</strong></a> para utilizar los Servicios.<br />
      </p>
    </li>
  </ul>
  <p class="p9"><br /></p>
  <h1><strong>Restricciones</strong></h1>
  <p class="p10">Usted acepta no hacerlo y no permitirá que otros:</p>
  <ul class="ul1">
    <li class="li11">
      <p class="s3">
        Licenciar, vender, alquilar, arrendar, asignar, distribuir, transmitir,
        alojar, subcontratar, divulgar o explotar comercialmente la plataforma o
        poner la plataforma a disposición de terceros.
      </p>
    </li>
    <li class="li11">
      <p class="s3">
        Modificar, realizar trabajos derivados, desensamblar, descifrar,
        realizar una compilación inversa o realizar ingeniería inversa de
        cualquier parte de la plataforma.
      </p>
    </li>
    <li class="li11">
      <p class="s3">
        Eliminar, alterar u ocultar cualquier aviso de propiedad (incluido
        cualquier aviso de derechos de autor o marca registrada) de sus
        afiliados, socios, proveedores o licenciatarios de la plataforma.
      </p>
    </li>
  </ul>
  <h1><strong>Pago</strong></h1>
  <p class="p10">
    Si se registra en cualquiera de nuestros planes de pago recurrente, acepta
    pagar todas las tarifas o cargos a su cuenta por el Servicio de acuerdo con
    las tarifas, los cargos y los términos de facturación vigentes en el momento
    en que cada tarifa o cargo vence y se paga. A menos que se indique lo
    contrario en un formulario de pedido, debe proporcionar a
    <a><strong>Elaboss Academy</strong></a> una tarjeta de crédito válida (Visa,
    MasterCard o cualquier otro emisor aceptado por nosotros) ("Proveedor de
    pagos") como condición para registrarse en el plan Premium. Su acuerdo de
    Proveedor de pagos rige el uso de la cuenta de tarjeta de crédito designada,
    y debe consultar ese acuerdo y no estos Términos para determinar sus
    derechos y responsabilidades con respecto a su Proveedor de pagos. Al
    proporcionar a <a><strong>Elaboss Academy</strong></a> su número de tarjeta
    de crédito y la información de pago asociada, acepta que
    <a><strong>Elaboss Academy</strong></a> está autorizado para verificar la
    información de inmediato y, posteriormente, facturar a su cuenta todas las
    tarifas y cargos adeudados y pagaderos a
    <a><strong>Elaboss Academy</strong></a> a continuación y que no se requiere
    ningún aviso o consentimiento adicional. Acepta notificar inmediatamente a
    <a><strong>Elaboss Academy</strong></a> de cualquier cambio en su dirección
    de facturación o en la tarjeta de crédito utilizada para el pago a
    continuación. <a><strong>Elaboss Academy</strong></a> se reserva el derecho
    en cualquier momento de cambiar sus precios y métodos de facturación, ya sea
    inmediatamente después de la publicación en nuestro Sitio o por correo
    electrónico al administrador de su organización.<br /><br />
    Cualquier honorario de abogado, costos judiciales u otros costos incurridos
    en el cobro de cantidades no disputadas en mora serán responsabilidad suya y
    serán pagados por usted. No existirá ningún contrato entre usted y
    <a><strong>Elaboss Academy</strong></a> para el Servicio hasta que
    <a><strong>Elaboss Academy</strong></a> acepte su pedido mediante un correo
    electrónico de confirmación, mensaje SMS / MMS u otro medio de comunicación
    apropiado. Usted es responsable de las tarifas de terceros en las que pueda
    incurrir al utilizar el Servicio.
  </p>

  
  <h1><strong>Política de Devolución y Reembolso</strong></h1>
  <p class="p10">
    Gracias por comprar en <a><strong>Elaboss Academy</strong></a
    >. Apreciamos el hecho de que le guste comprar las cosas que construimos.
    También queremos asegurarnos de que tenga una experiencia gratificante
    mientras explora, evalúa y compra nuestros servicios.<br />
    Al igual que con cualquier experiencia de compra, existen términos y
    condiciones que se aplican a las transacciones en
    <a><strong>Elaboss Academy</strong></a
    >. Seremos tan breves como lo permitan nuestros abogados. Lo principal que
    debe recordar es que al realizar un pedido o realizar una compra en
    <a><strong>Elaboss Academy</strong></a
    >, acepta los términos junto con la Política de privacidad de
    <a><strong>Elaboss Academy</strong></a
    >.<br />
    Si por alguna razón no está completamente satisfecho con algún bien o
    servicio que le brindamos, no dude en contactarnos y discutiremos cualquiera
    de los problemas que esté atravesando con nuestro producto.
  </p>
  <h1><strong>Tus sugerencias</strong></h1>
  <p class="p10">
    Cualquier, comentario, idea, mejora o sugerencia (colectivamente,
    "Sugerencias") que usted proporcione a
    <a><strong>Elaboss Academy</strong></a> con respecto a la plataforma
    seguirá siendo propiedad única y exclusiva de
    <a><strong>Elaboss Academy</strong></a
    >. <a><strong>Elaboss Academy</strong></a> tendrá la libertad de usar,
    copiar, modificar, publicar o redistribuir las Sugerencias para cualquier
    propósito y de cualquier manera sin ningún crédito o compensación para
    usted.
  </p>
  <h1><strong>Tu consentimiento</strong></h1>
  <p class="p10">
    Hemos actualizado nuestros Términos y condiciones para brindarle total
    transparencia sobre lo que se establece cuando visita nuestro sitio y cómo
    se utiliza. Al utilizar nuestra plataforma, registrar una cuenta o realizar
    una compra, por la presente acepta nuestros Términos y condiciones.
  </p>

  <h1><strong>Enlaces a otros Sitios Web</strong></h1>
  <p class="p10">
    Estos Términos y Condiciones se aplican solo a los Servicios. Los Servicios
    pueden contener enlaces a otros sitios web que
    <a><strong>Elaboss Academy</strong></a> no opera ni controla. No somos
    responsables por el contenido, la precisión o las opiniones expresadas en
    dichos sitios web, y dichos sitios web no son investigados, monitoreados o
    verificados por nuestra precisión o integridad. Recuerde que cuando utiliza
    un enlace para ir de los Servicios a otro sitio web, nuestros Términos y
    condiciones dejan de estar vigentes. Su navegación e interacción en
    cualquier otro sitio web, incluidos aquellos que tienen un enlace en nuestra
    plataforma, están sujetos a las propias reglas y políticas de ese sitio web.
    Dichos terceros pueden utilizar sus propias cookies u otros métodos para
    recopilar información sobre usted. >
  </p>

  <h1><strong>Cookies</strong></h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> utiliza "cookies" para identificar
    las áreas de nuestro sitio web que ha visitado. Una cookie es una pequeña
    porción de datos que su navegador web almacena en su computadora o
    dispositivo móvil. Usamos cookies para mejorar el rendimiento y la
    funcionalidad de nuestra plataforma, pero no son esenciales para su uso. Sin
    embargo, sin estas cookies, es posible que ciertas funciones, como los
    videos, no estén disponibles o se le solicitará que ingrese sus datos de
    inicio de sesión cada vez que visite la plataforma, ya que no podríamos
    recordar que había iniciado sesión anteriormente. La mayoría de los
    navegadores web se pueden configurar para desactivar el uso de cookies. Sin
    embargo, si desactiva las cookies, es posible que no pueda acceder a la
    funcionalidad de nuestro sitio web correctamente o en absoluto. Nunca
    colocamos información de identificación personal en cookies.
  </p>

  <h1><strong>Cambios en nuestros Términos y Condiciones</strong></h1>
  <p class="p10">
    Usted reconoce y acepta que <a><strong>Elaboss Academy</strong></a> puede
    dejar de brindarle (de forma permanente o temporal) el Servicio (o cualquier
    función dentro del Servicio) a usted o a los usuarios en general, a
    discreción exclusiva de <a><strong>Elaboss Academy</strong></a
    >, sin previo aviso. Puede dejar de utilizar el Servicio en cualquier
    momento. No es necesario que informe específicamente a
    <a><strong>Elaboss Academy</strong></a> cuando deje de usar el Servicio.
    Usted reconoce y acepta que si
    <a><strong>Elaboss Academy</strong></a> deshabilita el acceso a su cuenta,
    es posible que no pueda acceder al Servicio, los detalles de su cuenta o
    cualquier archivo u otro material contenido en su cuenta. Si decidimos
    cambiar nuestros Términos y condiciones, publicaremos esos cambios en esta
    página y / o actualizaremos la fecha de modificación de los Términos y
    condiciones a continuación.
  </p>
  <h1><strong>Modificaciones a nuestra plataforma</strong></h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> se reserva el derecho de modificar,
    suspender o interrumpir, temporal o permanentemente, la plataforma o
    cualquier servicio al que se conecte, con o sin previo aviso y sin
    responsabilidad ante usted.
  </p>
  <h1>Actualizaciones a nuestra plataforma</h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> puede, de vez en cuando,
    proporcionar mejoras a las características / funcionalidad de la plataforma,
    que pueden incluir parches, corrección de errores, actualizaciones, mejoras
    y otras modificaciones ("Actualizaciones"). Las actualizaciones pueden
    modificar o eliminar ciertas características y / o funcionalidades de la
    plataforma. Usted acepta que <a><strong>Elaboss Academy</strong></a> no
    tiene la obligación de (i) proporcionar Actualizaciones, o (ii) continuar
    proporcionándole o habilitando características y / o funcionalidades
    particulares de la plataforma. Además, acepta que todas las Actualizaciones
    (i) se considerarán una parte integral de la plataforma y (ii) estarán
    sujetas a los términos y condiciones de este Acuerdo.
  </p>
  <h1><strong>Servicios de Terceros</strong></h1>
  <p class="p10">
    Podemos mostrar, incluir o poner a disposición contenido de terceros
    (incluidos datos, información, aplicaciones y otros servicios de productos)
    o proporcionar enlaces a sitios web o servicios de terceros ("Servicios de
    terceros"). Usted reconoce y acepta que
    <a><strong>Elaboss Academy</strong></a> no será responsable de ningún
    Servicio de terceros, incluida su precisión, integridad, puntualidad,
    validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad
    o cualquier otro aspecto de los mismos.
    <a><strong>Elaboss Academy</strong></a> no asume ni tendrá ninguna
    obligación o responsabilidad ante usted o cualquier otra persona o entidad
    por los Servicios de terceros. Los Servicios de terceros y los enlaces a los
    mismos se brindan únicamente para su conveniencia y usted accede a ellos y
    los usa completamente bajo su propio riesgo y sujeto a los términos y
    condiciones de dichos terceros.
  </p>
  <h1><strong>Duración y Terminación</strong></h1>
  <p class="p10">
    Este Acuerdo permanecerá en vigor hasta que usted o
    <a><strong>Elaboss Academy</strong></a> lo rescindan.
    <a><strong>Elaboss Academy</strong></a> puede, a su entera discreción, en
    cualquier momento y por cualquier motivo o sin él, suspender o rescindir
    este Acuerdo con o sin previo aviso. Este Acuerdo terminará inmediatamente,
    sin previo aviso de <a><strong>Elaboss Academy</strong></a
    >, en caso de que no cumpla con alguna de las disposiciones de este Acuerdo.
    También puede rescindir este Acuerdo eliminando la plataforma y todas las
    copias del mismo de su computadora. Tras la rescisión de este Acuerdo,
    deberá dejar de utilizar la plataforma y eliminar todas las copias de la
    plataforma de su computadora. La rescisión de este Acuerdo no limitará
    ninguno de los derechos o recursos de
    <a><strong>Elaboss Academy</strong></a> por ley o en equidad en caso de
    incumplimiento por su parte (durante la vigencia de este Acuerdo) de
    cualquiera de sus obligaciones en virtud del presente Acuerdo.
  </p>
  <h1><strong>Aviso de infracción de Derechos de Autor</strong></h1>
  <p class="p10">
    Si usted es propietario de los derechos de autor o el agente de dicho
    propietario y cree que cualquier material de nuestra plataforma constituye
    una infracción de sus derechos de autor, comuníquese con nosotros y
    proporcione la siguiente información: (a) una firma física o electrónica del
    propietario de los derechos de autor o una persona autorizada para actuar en
    su nombre; (b) identificación del material que se alega infringe; (c) su
    información de contacto, incluida su dirección, número de teléfono y un
    correo electrónico; (d) una declaración suya de que cree de buena fe que el
    uso del material no está autorizado por los propietarios de los derechos de
    autor; y (e) la declaración de que la información en la notificación es
    precisa y, bajo pena de perjurio, usted está autorizado a actuar en nombre
    del propietario.
  </p>
  <h1><strong>Indemnización</strong></h1>
  <p class="p10">
    Usted acepta indemnizar y eximir de responsabilidad a
    <a><strong>Elaboss Academy</strong></a> y a sus empresas matrices,
    subsidiarias, afiliadas, funcionarios, empleados, agentes, socios y
    otorgantes de licencias (si corresponde) de cualquier reclamo o demanda,
    incluidos los honorarios razonables de abogados, debido a que surja de su:
    (a) uso de la plataforma; (b) violación de este Acuerdo o cualquier ley o
    reglamento; o (c) violación de cualquier derecho de un tercero.
  </p>
  <h1><strong>Sin garantías</strong></h1>
  <p class="p10">
    La plataforma se le proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD" y con
    todas las fallas y defectos sin garantía de ningún tipo. En la medida máxima
    permitida por la ley aplicable,
    <a><strong>Elaboss Academy</strong></a
    >, en su propio nombre y en nombre de sus afiliados y sus respectivos
    licenciantes y proveedores de servicios, renuncia expresamente a todas las
    garantías, ya sean expresas, implícitas, legales o de otro tipo, con con
    respecto a la plataforma, incluidas todas las garantías implícitas de
    comerciabilidad, idoneidad para un propósito particular, título y no
    infracción, y garantías que puedan surgir del curso del trato, el curso del
    desempeño, el uso o la práctica comercial. Sin limitación a lo anterior,
    <a><strong>Elaboss Academy</strong></a> no ofrece garantía ni compromiso, y
    no hace ninguna representación de ningún tipo de que la plataforma cumplirá
    con sus requisitos, logrará los resultados previstos, será compatible o
    funcionará con cualquier otro software, sitios web, sistemas o servicios,
    operen sin interrupciones, cumplan con los estándares de rendimiento o
    confiabilidad o que no tengan errores o que cualquier error o defecto puede
    o será corregido.<br /><br />
    Sin limitar lo anterior, ni <a><strong>Elaboss Academy</strong></a> ni
    ningún proveedor de <a><strong>Elaboss Academy</strong></a> hace ninguna
    representación o garantía de ningún tipo, expresa o implícita: (i) en cuanto
    al funcionamiento o disponibilidad de la plataforma, o la información,
    contenido y materiales o productos incluidos en el mismo; (ii) que la
    plataforma será ininterrumpida o libre de errores; (iii) en cuanto a la
    precisión, confiabilidad o vigencia de cualquier información o contenido
    proporcionado a través de la plataforma; o (iv) que la plataforma, sus
    servidores, el contenido o los correos electrónicos enviados desde o en
    nombre de <a><strong>Elaboss Academy</strong></a> están libres de virus,
    scripts, troyanos, gusanos, malware, bombas de tiempo u otros componentes
    nocivos. Algunas jurisdicciones no permiten la exclusión o las limitaciones
    de las garantías implícitas o las limitaciones de los derechos legales
    aplicables de un consumidor, por lo que algunas o todas las exclusiones y
    limitaciones anteriores pueden no aplicarse en su caso.
  </p>
  <h1><strong>Limitación de Responsabilidad</strong></h1>
  <p class="p10">
    Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad
    total de <a><strong>Elaboss Academy</strong></a> y cualquiera de sus
    proveedores en virtud de cualquier disposición de este Acuerdo y su recurso
    exclusivo por todo lo anterior se limitará al monto realmente pagado por
    usted para la plataforma. En la máxima medida permitida por la ley
    aplicable, en ningún caso <a><strong>Elaboss Academy</strong></a> o sus
    proveedores serán responsables de ningún daño especial, incidental,
    indirecto o consecuente de ningún tipo (incluidos, entre otros, daños por
    lucro cesante, por pérdida de datos u otra información, por interrupción del
    negocio, por lesiones personales, por la pérdida de privacidad que surja de
    alguna manera relacionada con el uso o la imposibilidad de usar la
    plataforma, software de terceros y / o - hardware de terceros utilizado con
    la plataforma, o de otro modo en relación con cualquier disposición de este
    Acuerdo), incluso si <a><strong>Elaboss Academy</strong></a> o cualquier
    proveedor ha sido informado de la posibilidad de tales daños e incluso si el
    recurso no cumple con su propósito esencial. Algunos estados /
    jurisdicciones no permiten la exclusión o limitación de daños incidentales o
    consecuentes, por lo que es posible que la limitación o exclusión anterior
    no se aplique en su caso.
  </p>
  <h1><strong>Divisibilidad</strong></h1>
  <p class="p10">
    Si alguna disposición de este Acuerdo se considera inaplicable o inválida,
    dicha disposición se cambiará e interpretará para lograr los objetivos de
    dicha disposición en la mayor medida posible según la ley aplicable y las
    disposiciones restantes continuarán en pleno vigor y efecto. Este Acuerdo,
    junto con la Política de privacidad y cualquier otro aviso legal publicado
    por <a><strong>Elaboss Academy</strong></a> en los Servicios, constituirá el
    acuerdo completo entre usted y <a><strong>Elaboss Academy</strong></a> con
    respecto a los Servicios. Si alguna disposición de este Acuerdo es
    considerada inválida por un tribunal de jurisdicción competente, la
    invalidez de dicha disposición no afectará la validez de las disposiciones
    restantes de este Acuerdo, que permanecerán en pleno vigor y efecto. Ninguna
    renuncia a cualquier término de este Acuerdo se considerará una renuncia
    adicional o continua de dicho término o cualquier otro término, y el hecho
    de que no haga valer ningún derecho o disposición en virtud de este Acuerdo
    no constituirá una renuncia a dicho derecho. o provisión. USTED Y ACEPTAN
    QUE CUALQUIER CAUSA DE ACCIÓN QUE SURJA O ESTÉ RELACIONADA CON LOS SERVICIOS
    DEBE COMENZAR DENTRO DE UN (1) AÑO DESPUÉS DE QUE LA CAUSA DE ACCIÓN SE
    ACUERDA. DE LO CONTRARIO, DICHA CAUSA DE ACCIÓN ESTÁ PERMANENTEMENTE
    PROHIBIDA.
  </p>
  <h1><strong>Renuncia</strong></h1>
  <p class="p10">
    Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho o
    exigir el cumplimiento de una obligación en virtud de este Acuerdo no
    afectará la capacidad de una de las partes para ejercer dicho derecho o
    requerir dicho cumplimiento en cualquier momento posterior, ni constituirá
    la renuncia a una infracción. cualquier incumplimiento posterior.<br /><br />
    Ninguna falla en el ejercicio, ni demora en el ejercicio, por parte de
    cualquiera de las partes, de cualquier derecho o poder bajo este Acuerdo
    operará como una renuncia a ese derecho o poder. El ejercicio único o
    parcial de cualquier derecho o poder en virtud de este Acuerdo tampoco
    impedirá el ejercicio posterior de ese o cualquier otro derecho otorgado en
    este documento. En caso de conflicto entre este Acuerdo y cualquier compra u
    otros términos aplicables, regirán los términos de este Acuerdo.
  </p>
  <h1><strong>Enmiendas a este Acuerdo</strong></h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> se reserva el derecho, a su entera
    discreción, de modificar o reemplazar este Acuerdo en cualquier momento. Si
    una revisión es importante, proporcionaremos un aviso de al menos 30 días
    antes de que entren en vigencia los nuevos términos. Lo que constituye un
    cambio material se determinará a nuestro exclusivo criterio. Si continúa
    accediendo o utilizando nuestra plataforma después de que las revisiones
    entren en vigencia, usted acepta estar sujeto a los términos revisados. Si
    no está de acuerdo con los nuevos términos, ya no está autorizado para usar
    <a><strong>Elaboss Academy</strong></a
    >.
  </p>
  <h1><strong>Acuerdo completo</strong></h1>
  <p class="p10">
    El Acuerdo constituye el acuerdo completo entre usted y
    <a><strong>Elaboss Academy</strong></a> con respecto a su uso de la
    plataforma y reemplaza todos los acuerdos escritos u orales anteriores y
    contemporáneos entre usted y <a><strong>Elaboss Academy</strong></a
    >. Es posible que esté sujeto a términos y condiciones adicionales que se
    aplican cuando usa o compra otros servicios de
    <a><strong>Elaboss Academy</strong></a
    >, que <a><strong>Elaboss Academy</strong></a> le proporcionará en el
    momento de dicho uso o compra.
  </p>
  <h1><strong>Actualizaciones de nuestros Términos</strong></h1>
  <p class="p10">
    Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que
    debamos realizar cambios en estos Términos para que reflejen con precisión
    nuestro Servicio y nuestras políticas. A menos que la ley exija lo
    contrario, le notificaremos (por ejemplo, a través de nuestro Servicio)
    antes de realizar cambios en estos Términos y le daremos la oportunidad de
    revisarlos antes de que entren en vigencia. Luego, si continúa utilizando el
    Servicio, estará sujeto a los Términos actualizados. Si no desea aceptar
    estos o alguno de los Términos actualizados, puede eliminar su cuenta.
  </p>
  <h1><strong>Propiedad intelectual</strong></h1>
  <p class="p10">
    La plataforma y todo su contenido, características y funcionalidad (que
    incluyen, entre otros, toda la información, software, texto, pantallas,
    imágenes, video y audio, y el diseño, selección y disposición de los
    mismos), son propiedad de <a><strong>Elaboss Academy</strong></a
    >, sus licenciantes u otros proveedores de dicho material y están protegidos
    por Colombia y leyes internacionales de derechos de autor, marcas
    registradas, patentes, secretos comerciales y otras leyes de propiedad
    intelectual o derechos de propiedad. El material no puede ser copiado,
    modificado, reproducido, descargado o distribuido de ninguna manera, en su
    totalidad o en parte, sin el permiso previo expreso por escrito de
    <a><strong>Elaboss Academy</strong></a
    >, a menos que y excepto que se indique expresamente en estos Términos y
    Condiciones. Se prohíbe cualquier uso no autorizado del material.
  </p>
  <h1><strong>Acuerdo de Arbitraje</strong></h1>
  <p class="p10">
    Esta sección se aplica a cualquier disputa, EXCEPTO QUE NO INCLUYE UNA
    DISPUTA RELACIONADA CON RECLAMOS POR RECURSOS INJUNTIVOS O EQUITATIVOS CON
    RESPECTO A LA EJECUCIÓN O VALIDEZ DE SUS DERECHOS DE PROPIEDAD INTELECTUAL O
    DE <a><strong>Elaboss Academy</strong></a
    >. El término "disputa" significa cualquier disputa, acción u otra
    controversia entre usted y <a><strong>Elaboss Academy</strong></a> en
    relación con los Servicios o este acuerdo, ya sea en contrato, garantía,
    agravio, estatuto, regulación, ordenanza o cualquier otra base legal o
    equitativa. "Disputa" tendrá el significado más amplio posible permitido por
    la ley.
  </p>
  <h1><strong>Aviso de Disputa</strong></h1>
  <p class="p10">
    En el caso de una disputa, usted o
    <a><strong>Elaboss Academy</strong></a> deben darle al otro un Aviso de
    Disputa, que es una declaración escrita que establece el nombre, la
    dirección y la información de contacto de la parte que la proporcionó, los
    hechos que dieron lugar a la disputa y la reparación solicitada. Debe enviar
    cualquier Aviso de disputa por correo electrónico a: mariana@<strong
      >Elaboss Academy</strong
    >. <a><strong>Elaboss Academy</strong></a> le enviará cualquier Aviso de
    disputa por correo a su dirección si la tenemos, o de otra manera a su
    dirección de correo electrónico. Usted y
    <a><strong>Elaboss Academy</strong></a> intentarán resolver cualquier
    disputa mediante una negociación informal dentro de los sesenta (60) días a
    partir de la fecha en que se envíe la Notificación de disputa. Después de
    sesenta (60) días, usted o <a><strong>Elaboss Academy</strong></a> pueden
    comenzar el arbitraje.
  </p>
  <h1><strong>Arbitraje Obligatorio</strong></h1>
  <p class="p10">
    Si usted y <a><strong>Elaboss Academy</strong></a> no resuelven ninguna
    disputa mediante una negociación informal, cualquier otro esfuerzo para
    resolver la disputa se llevará a cabo exclusivamente mediante arbitraje
    vinculante como se describe en esta sección. Está renunciando al derecho de
    litigar (o participar como parte o miembro de la clase) todas las disputas
    en la corte ante un juez o jurado. La disputa se resolverá mediante
    arbitraje vinculante de acuerdo con las reglas de arbitraje comercial de la
    Asociación Americana de Arbitraje. Cualquiera de las partes puede buscar
    medidas cautelares provisionales o preliminares de cualquier tribunal de
    jurisdicción competente, según sea necesario para proteger los derechos o la
    propiedad de las partes en espera de la finalización del arbitraje. Todos y
    cada uno de los costos, honorarios y gastos legales, contables y de otro
    tipo en los que incurra la parte ganadora correrán a cargo de la parte no
    ganadora.
  </p>
  <h1><strong>Envíos y Privacidad</strong></h1>
  <p class="p10">
    En el caso de que envíe o publique ideas, sugerencias creativas, diseños,
    fotografías, información, anuncios, datos o propuestas, incluidas ideas para
    productos, servicios, funciones, tecnologías o promociones nuevos o
    mejorados, acepta expresamente que dichos envíos se realizarán
    automáticamente. será tratado como no confidencial y no propietario y se
    convertirá en propiedad exclusiva de
    <a><strong>Elaboss Academy</strong></a> sin ningún tipo de compensación o
    crédito para usted. <a><strong>Elaboss Academy</strong></a> y sus afiliados
    no tendrán obligaciones con respecto a dichos envíos o publicaciones y
    pueden usar las ideas contenidas en dichos envíos o publicaciones para
    cualquier propósito en cualquier medio a perpetuidad, incluyendo, pero no
    limitado a desarrollo, fabricación, y comercializar productos y servicios
    utilizando tales ideas.
  </p>
  <h1><strong>Promociones</strong></h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> puede, de vez en cuando, incluir
    concursos, promociones, sorteos u otras actividades ("Promociones") que
    requieren que envíe material o información sobre usted. Tenga en cuenta que
    todas las promociones pueden regirse por reglas independientes que pueden
    contener ciertos requisitos de elegibilidad, como restricciones de edad y
    ubicación geográfica. Usted es responsable de leer todas las reglas de
    Promociones para determinar si es elegible para participar o no. Si
    participa en alguna Promoción, acepta cumplir con todas las Reglas de
    promociones. Es posible que se apliquen términos y condiciones adicionales a
    las compras de bienes o servicios a través de los Servicios, cuyos términos
    y condiciones forman parte de este Acuerdo mediante esta referencia.
  </p>
  <h1><strong>Errores Tipográficos</strong></h1>
  <p class="p10">
    En el caso de que un producto y / o servicio se enumere a un precio
    incorrecto o con información incorrecta debido a un error tipográfico,
    tendremos el derecho de rechazar o cancelar cualquier pedido realizado para
    el producto y / o servicio enumerado al precio incorrecto. Tendremos derecho
    a rechazar o cancelar cualquier pedido, ya sea que se haya confirmado o no y
    se haya cargado a su tarjeta de crédito. Si su tarjeta de crédito ya ha sido
    cargada por la compra y su pedido es cancelado, emitiremos inmediatamente un
    crédito a su cuenta de tarjeta de crédito u otra cuenta de pago por el monto
    del cargo.
  </p>
  <h1><strong>Diverso</strong></h1>
  <p class="p10">
    Si por alguna razón un tribunal de jurisdicción competente determina que
    alguna disposición o parte de estos Términos y condiciones no se puede hacer
    cumplir, el resto de estos Términos y condiciones continuará en pleno vigor
    y efecto. Cualquier renuncia a cualquier disposición de estos Términos y
    condiciones será efectiva solo si está por escrito y firmada por un
    representante autorizado de <a><strong>Elaboss Academy</strong></a
    >. <a><strong>Elaboss Academy</strong></a> tendrá derecho a una medida
    cautelar u otra compensación equitativa (sin la obligación de depositar
    ninguna fianza o garantía) en caso de incumplimiento anticipado por su
    parte. <a><strong>Elaboss Academy</strong></a> opera y controla el Servicio
    <a><strong>Elaboss Academy</strong></a> desde sus oficinas en Colombia. El
    Servicio no está destinado a ser distribuido ni utilizado por ninguna
    persona o entidad en ninguna jurisdicción o país donde dicha distribución o
    uso sea contrario a la ley o regulación. En consecuencia, las personas que
    eligen acceder al Servicio <a><strong>Elaboss Academy</strong></a> desde
    otras ubicaciones lo hacen por iniciativa propia y son las únicas
    responsables del cumplimiento de las leyes locales, en la medida en que las
    leyes locales sean aplicables. Estos Términos y condiciones (que incluyen e
    incorporan la Política de privacidad de
    <a><strong>Elaboss Academy</strong></a
    >) contienen el entendimiento completo y reemplazan todos los entendimientos
    previos entre usted y <a><strong>Elaboss Academy</strong></a> con respecto a
    su tema, y ​​usted no puede cambiarlos ni modificarlos. . Los títulos de las
    secciones que se utilizan en este Acuerdo son solo por conveniencia y no se
    les dará ninguna importancia legal.
  </p>
  <h1><strong>Descargo de Responsabilidad</strong></h1>
  <p class="p10">
    <a><strong>Elaboss Academy</strong></a> no es responsable de ningún
    contenido, código o cualquier otra imprecisión.
    <a><strong>Elaboss Academy</strong></a> no ofrece garantías. En ningún caso
    será responsable de ningún daño especial, directo, indirecto, consecuente o
    incidental o de cualquier daño, ya sea en una acción contractual,
    negligencia u otro agravio, que surja de o en conexión con el uso del
    Servicio o el contenido del Servicio. se reserva el derecho de realizar
    adiciones, eliminaciones o modificaciones al contenido del Servicio en
    cualquier momento sin previo aviso.<br /><br />
    El Servicio y su contenido se proporcionan "tal cual" y "según esté
    disponible" sin ninguna garantía o representación de ningún tipo, ya sea
    expresa o implícita. es un distribuidor y no un editor del contenido
    proporcionado por terceros; como tal, no ejerce ningún control editorial
    sobre dicho contenido y no ofrece ninguna garantía o representación en
    cuanto a la precisión, confiabilidad o vigencia de cualquier información,
    contenido, servicio o mercancía proporcionada o accesible a través del
    Servicio . Sin limitar lo anterior, renuncia específicamente a todas las
    garantías y representaciones en cualquier contenido transmitido en conexión
    con el Servicio o en sitios que pueden aparecer como enlaces en el Servicio
    , o en los productos proporcionados como parte o en relación con el Servicio
    , incluidas, entre otras, las garantías de comerciabilidad, idoneidad para
    un propósito particular o no infracción de derechos de terceros. Ningún
    consejo oral o información escrita proporcionada por o cualquiera de sus
    afiliados, empleados, funcionarios, directores, agentes o similares creará
    una garantía. La información sobre precios y disponibilidad está sujeta a
    cambios sin previo aviso. Sin limitar lo anterior, no garantiza que el
    Servicio de sea ininterrumpido, sin corrupción, oportuno o sin errores.
  </p>
  <h1><strong>Contáctenos</strong></h1>
  <p class="p10">No dude en contactarnos si tiene alguna pregunta.</p>
  <ul class="ul1">
    <li class="li16">
      <p class="s3">
        A través de correo electrónico:
        <a href="mailto:mariana@elabossacademy.com"><strong>mariana@elabossacademy.com</strong></a>

      </p>
    </li>
  </ul>
</div>
