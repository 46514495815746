<div class="container font-blackerSansText">
  <section>
    <div class="">
      <h1>✨¡Ya tienes acceso a la Serie de mi Masterclass gratuita!🥂</h1>
      <h2>Estos son los siguientes pasos para empezar</h2>
      <h2 class="space">⬇️</h2>
    </div>
  </section>

  <section>
    <div>
      <h3 class="space">Paso 1️⃣</h3>
      <p>Únete a la comunidad privada de 🥂Elabosses:</p>
      <p>
        El canal dónde tendrás acceso a un material extra con prácticas potentes
        y contenido exclusivo.
      </p>
    </div>
  </section>

  <section>
    <div>
      <h3 class="space">Paso 2️⃣</h3>
      <p>Busca la confirmación de tu acceso en tu e-mail:</p>
      <p>
        En 1-2 mintuos recibirás un correo al correo con el que te registraste <strong>({{email}})</strong>  con el acceso a la plataforma donde
        podrás ver las clases.
      </p>
      <p>
        Si no encuentras este correo, por favor revisa en Spam (que a veces
        llega aquí).
      </p>
      <p>
        Y si te llega a Spam, asegúrate de agregar el correo
        “mariana@elabossacademy” a tus contactos para que los próximos correos
        te lleguen bien.
      </p>
    </div>
  </section>
  <section>
    <div>
        <p>
            Por último, ten en cuenta que que tienes acceso a las clases, de forma
            gratuita, solo durante 10 días y que, para poder entrar a la plataforma
            donde están alojadas, debes acceder con el mismo correo de Gmail que nos
            proporcionaste en tu registro <strong>({{email}})</strong>.
          </p>
    </div>

  </section>
</div>
