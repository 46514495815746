import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: any): any {
    if (!items || !filter) {
        
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out

    
    // return items.filter((item: any) => item.name.toLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
    return items.filter((item: any) => item.search.toLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1);
  }
}
