import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  link1Pago: string =
    'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=3W2NUA2ST97S8';
  link2Pagos: string =
    'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2MX887576P9369009MKGGNJQ';
  link3Pagos: string =
    'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5H362825BA5393815MKGGOJQ';

  link2Pagos1: string =
    'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8L8952105H7029021MKVCXAI';
  linkPagoFull: string =
    'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=92KELFLRR45ES';


  // 349 usd
  link2Pagos2: string =
    'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4P765766M3328392VMK2RFJY';
  linkPagoFull2: string =
    'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KRK38V9CRBRV4';

  // 399 usd
  link3Pagos2: string =
    'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4P765766M3328392VMK2RFJY';
  linkPagoFull3: string =
    'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KRK38V9CRBRV4';

  constructor(private router: Router) {}
  ngOnInit(): void {}
  link(link: string) {
    window.open(link);
  }
}
