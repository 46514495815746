import { Injectable } from '@angular/core';

@Injectable()
export class LoadScriptsService {
  constructor() {}

  stripe() {
    const scriptName = 'stripe-script'

    if (!window.document.getElementById(scriptName)) {
      // const script = window.document.createElement('script');
      // script.id = 'stripe-script';
      // script.type = 'text/javascript';
      // script.src = 'https://js.stripe.com/v3/';
      // window.document.body.appendChild(script);
      createScript(scriptName)
    }
    else {
      window.document.getElementById(scriptName)?.remove()
      createScript(scriptName)
    }
    
  }
}
function createScript(scriptName:string){
  const script = window.document.createElement('script');
  script.id = scriptName ;
  script.type = 'text/javascript';
  script.src = 'https://js.stripe.com/v3/';
  window.document.body.appendChild(script);  
}