import { AfterContentInit, Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { LoadScriptsService } from 'src/app/shared/services/loadScripts.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { WaitingComponent } from '../../../../waiting/waiting.component';

@Component({
  selector: 'app-checkout-link',
  templateUrl: './checkout-link.component.html',
  styleUrls: ['./checkout-link.component.css']
})
export class CheckoutLinkComponent implements OnInit, AfterContentInit {
  constructor(
    // @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    // private stripe: StripeService,
    // private loadScripts: LoadScriptsService,
    private dialog: MatDialog,
    // private snack: MatSnackBar,
    private modal: ModalService, // private modal: ModalService
    private inject: Injector
  ) {

    // this.publishablekey=''
    // this.src=this.matDialogdata.src
  }

  paynentHandler: any = null;

;
  price = 0;
  product = '';

  ngOnInit() {
    // this.loadScripts.stripe();
    // const img = document.querySelector('#product') as HTMLImageElement;
    // img.src = this.matDialogdata.img;
    // if ('couponState' in this.matDialogdata) {
    //   if (this.matDialogdata.couponState == 'valid') {
    //     this.openSnackBar('Cupon agregado satisfactoriamente', 'ok');
    //   } else {
    //     if (this.matDialogdata.couponState == 'expired') {
    //       this.openSnackBar('El cupon ya expiro', 'ok');
    //     } else {
    //       if (this.matDialogdata.couponState == 'invalid') {
    //         this.openSnackBar('Cupon no valido', 'ok');
    //       }
    //     }
    //   }
    // }
  }

  // openSnackBar(message: string, action: string) {

  //   const snackBarRef = this.snack.open(message, action, {
  //     duration: 3000,
  //   });
  //   snackBarRef.onAction().subscribe({
  //     next(value) {
  //     },
  //   });
  // }

  ngAfterContentInit(): void {
    this.inject.get(StripeService).getPublishableKey().subscribe({
      next:(v) =>{
        // console.log('next',v);
        
        const { publishablekey } = (v.body as any);

        this.createPaymentIntent(publishablekey)
        

        
      }

    })
    
    // this.createPaymentIntent(this.matDialogdata, this.stripe);
  }
  createPaymentIntent(publishableKey: any) {

    // sacarpublishablekye
    const script = window.document.getElementById('stripe-script');
    let stripeJS: any;
    script!.onload = () => {
      stripeJS = (<any>window).Stripe(publishableKey);
    };
    // const stripeJS = (<any>window).Stripe(this.matDialogdata.publishableKey);
    const data = {
      price:1100,
      product:'Club',
    }
    this.inject.get(StripeService).createPaymentIntent(publishableKey).subscribe((v) => {
      const { clientSecret, price, product } = v.body as any;

      this.price = price / 100;
      this.product = product;

      // const stripe = (<any>window).Stripe(this.matDialogdata.publishableKey);

      const elements = stripeJS.elements({ clientSecret });

      const paymentElement = elements.create('payment');

      paymentElement.mount('#payment-element');

      const form = document.getElementById('payment-form');

      form!.addEventListener('submit', async (e) => {
        e.preventDefault();

        const { error } = await stripeJS.confirmPayment({
          elements,
          confirmParams: {
            return_url:
              window.location.origin +
              window.location.pathname +
              '/payments/complete',
          },
        });
        if (error) {
          const messages = document.getElementById('error-messages');
          messages!.innerText = error.message;

          this.modal.close(this.dialog);
        }
      });
    });
  }

  processing() {
    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
      // data: this.matDialogdata,
    };
    this.modal.modalForm(
      this.dialog,
      // this.matDialogdata,
      WaitingComponent,
      options
    );
  }
}
