<div>
    <h1>Aviso legal y términos y condiciones de uso de JHTS</h1>
  
    <p>
      Abajo encontrarás toda la información esencial en cuánto a los
      <strong>términos</strong>, <strong>condiciones</strong> y
      <strong>avisos legales</strong> del <strong>curso</strong>: JHTS (Juicy High Ticket Sales)
    </p>
  
    <h1>Forma de pago</h1>
  
    <p>
      El alumno deberá pagar el <strong>precio</strong> del curso mediante la
      plataforma de pagos Stripe, PayPal o con tarjeta de crédito o de débito, en
      las modalidades indicadas al momento de la venta.
    </p>
  
    <p>
      El incumplimiento en el pago de una de las cuotas, de presentarse la opción
      del pago por plazos al momento de la venta, dará derecho a Elaboss Academy
      (Mariana Durán Mejía) a <strong>suspender</strong> el
      <strong>acceso</strong> total a la plataforma y al servicio hasta que se
      complete el pago de la totalidad pendiente. Los pagos parciales que el
      alumno haya hecho <strong>no</strong> le serán <u>devueltos</u>, ya que se trata de
      una compra por prestación de servicios cuya prestación comienza con el
      acuerdo del consumidor.
    </p>
  
    <h1>Devoluciones</h1>
  
    <p>
      Luego de realizada la compra,
      <strong>no hay derecho al desistimiento</strong>, es decir, no se devolverá
      el dinero ni se anulará o congelará la compra, por ser un producto digital,
      sin soporte material (físico), <strong>que inicia</strong> con el acuerdo
      del alumno/a, luego de realizar su compra.
    </p>
  
    <p>
      Por ello, habiéndose entregado los presentes
      <strong>términos y condiciones</strong> y habiendo sido aceptados al momento
      de compra por el/la alumno/a, contratando los SERVICIOS de Elaboss Academy
      (Mariana Durán Mejía), se
      <strong>consiente de forma expresa que</strong> el/la alumno/a
      <strong
        >perderá el derecho de desistimiento desde el momento en el que haya
        iniciado el SERVICIO con cualquier acceso al material del curso,
        independientemente de si EL ALUMNO consumió o no el mismo; no estando
        obligado Elaboss Academy (Mariana Durán Mejía), en ese caso, al reembolso
        de los importes satisfechos con ocasión de la contratación del curso que
        se trate.</strong
      >
    </p>
  
    <p>
      Y, por ello, una vez que se ha realizado una factura, un depósito o un pago,
      no hay reembolsos, reembolsos parciales o cambios.
    </p>
  
    <p>
      El desconocimiento de estos términos no dará derecho al alumno de exigir
      devolución alguna del pago realizado.
    </p>
  
    <p>
      Elaboss Academy (Mariana Durán Mejía) sólo realizará devoluciones cuando
      esta misma no pueda proveer el servicio pactado.
    </p>
  
    <h1>Renuncia</h1>
  
    <p>
      El/la alumno/a <strong>tampoco</strong> tendrá derecho a devolución alguna
      en caso de renunciar a continuar con la formación, una vez haya comenzado la
      ejecución del servicio y del contenido digital puesto a su disposición,
      <strong>independientemente de si EL ALUMNO consumió o no el mismo.</strong>
    </p>
  
    <h1>Entrega de la información del curso y Licencia</h1>
  
    <p>
      Cada compra de los <strong>Servicios</strong> de Elaboss Academy (Mariana
      Durán Mejía) le otorga <strong>acceso</strong> a una plataforma interna de
      Elaboss Academy.
    </p>
  
    <p>
      Cuando el alumno compra el curso, Elaboss Academy (Mariana Durán Mejía), le
      otorga una licencia limitada (de 15 dias contado a partir de la fecha en que
      compró el curso y/o ELABOSS ACADEMY le haya
      <strong
        >empezado a enviar el acceso al material a su correo electrónico)</strong
      >
      ;
      <strong
        >una licencia que es personal, NO comercial, revocable, limitada, no
        exclusiva, intransferible y no sublicenciable</strong
      >
      para acceder y utilizar el programa específico y cualquier software,
      contenido, equipo u otros materiales relacionados, para sus necesidades
      específicas, en los tiempos y formas establecidas aquí, en el contrato y sus
      anexos.
    </p>
  
    <p>
      El curso está diseñado para usarse en
      <strong>UN SÓLO USUARIO</strong> dentro del plazo de la licencia únicamente.
      <strong>Está totalmente prohibido compartir su licencia.</strong> Esto
      incluye compartir cualquier material complementario del programa, archivos y
      acceso a cualquier otro aspecto del programa.
    </p>
  
    <h1>Bonus</h1>
  
    <p>
      Dependiendo del momento de la compra, el alumno podrá tener derecho a unos
      determinados bonuses <strong>exclusivos</strong> y
      <strong>limitados</strong>, que han sido expresamente y claramente indicados
      al momento de la venta, ya sea a través de la información brindada por
      Whatsapp y/o elementos publicitarios que se hayan dispuesto para la venta
      del curso.
    </p>
  
    <p>
      A dichos bonuses aplican <strong>términos</strong> y
      <strong>condiciones</strong> y de no cumplirse alguno, el alumno podría
      perder el derecho parcial o completo al bonus o los bonuses.
    </p>
  
    <h1>Modalidad</h1>
  
    <p>
      Este es un curso con <strong>todo</strong> un
      <strong>contenido pre-grabado</strong>, SIN una obligación de
      <strong>soporte</strong> por parte de Mariana distinta a las apariciones
      sorpresa que ella desee hacer en la comunidad exclusiva de Telegram; SIN
      estar obligada a dar aviso previo.
    </p>
  
    <h1>Requisitos de sistema</h1>
  
    <h3>Para poder disfrutar correctamente del curso, el alumno/a necesitará:</h3>
    <ul>
      <li>Un ordenador con pantalla de colores.</li>
      <li>Conexión a internet.</li>
      <li>Posibilidad de ver archivos con la extensión .pdf.</li>
      <li>
        Posibilidad de ver videos (si puedes entrar en Youtube, Vimeo, puedes ver
        los videos del curso sin problemas).
      </li>
      <li>
        Un ratón, un teclado; es decir todos los componentes que un ordenador
        necesite para ser utilizado.
      </li>
      <li>
        También puedes ver el curso en un dispositivo móvil, aunque por comodidad,
        se recomienda un ordenador o computadora.
      </li>
      <li>Teléfono móvil gama media.</li>
    </ul>
  
    <p>
      En todo momento el equipo de soporte de Elaboss Academy estará en la
      disponibilidad de ayudar al alumno con los
      <strong>problemas técnicos</strong> que pueda tener con la plataforma dónde
      estará alojado el curso, mediante consejos que el alumno deberá implementar
      bajo su propia responsabilidad, ya que esta ayuda no sustituye ningún
      asesoramiento técnico a su equipo.
    </p>
  
    <h1>Asesorías personalizadas y/o acompañamiento</h1>
  
    <p>
      El programa
      <strong
        >no contempla acompañamiento privado y/o asesorías personalizadas o
        individuales</strong
      >, ya que el curso (JHTS) es A TU RITMO (de auto-aprendizaje).
    </p>
  
    <p>
      Por lo anterior, <strong>no</strong> se responderán preguntas al alumno ni
      por correo, ni por Instagram, ni por Whatsapp.
    </p>
  
    <p>
      *Excepción: si el almuno requiere <strong>soporte técnico</strong> o tiene
      alguna
      <strong>duda para acceder correctamente a los espacios del curso</strong>.
    </p>
  
    <h1>Propiedad intelectual</h1>
  
    <p>
      La propiedad intelectual del CURSO, así como el CONTENIDO creado por MARIANA
      DURÁN MEJÍA para la impartición del curso, son de
      <strong>propiedad exclusiva</strong> de MARIANA DURÁN MEJÍA.
    </p>
  
    <p>
      Esto quiere decir que <strong>todo el contenido</strong> está protegido por
      <strong
        >derechos de autor. No se autoriza, bajo ningún escenario; su
        distribución, modificación, venta, alquiler o cualquier otra manera para
        compartir o replicar todo o parte del contenido de</strong
      >
      Elaboss Academy (Mariana Durán Mejía)
      <strong>, sea de forma gratuita o no.</strong> También aplican las políticas
      relacionadas con la <u>propiedad intelectual</u> propias de la
      <strong>información de venta del programa.</strong>
    </p>
  
    <p>
      Y por lo tanto,
      <strong
        >se obliga especialmente a no explotar, copiar, plagiar directamente o
        plagiar de forma simulada propiedad intelectual de MARIANA DURÁN
        MEJÍA</strong
      >
      de forma gratuita u onerosa. Esto aplica para cualquier contenido
      <u>textual</u> o <u>visual</u>,
      <strong>parcial o totalmente similar</strong> al que se encuentre tanto en
      la
      <strong
        ><u><i>información de venta del programa</i></u></strong
      >
      o que haya sido
      <strong
        ><u><i>impartido en el programa</i></u></strong
      >. Todos los derechos de <strong>comercialización</strong> del contenido,
      así como su disposición, son de forma exclusiva de Elaboss Academy (Mariana
      Durán Mejía).
    </p>
  
    <p>
      Cualquier intento de <strong><u>competencia desleal</u></strong> y/o
      <strong><u>plagio</u></strong> de forma gratuita u onerosa; con una idea,
      conceptos, temarios, contenido textual o visual,
      <strong>parcial o totalmente similar</strong> al que se encuentre en la
      <strong
        ><u><i>información de venta del programa</i></u></strong
      >
      o que haya sido
      <strong
        ><u><i>impartido en el programa</i></u></strong
      >
      dará derecho a Elaboss Academy (Mariana Durán Mejía) a <u>suspender</u> el
      acceso total a la plataforma y al servicio hasta que se aclaren los hechos.
      De no poderse aclarar los hechos necesarios, esto dará derecho a Elaboss
      Academy (Mariana Durán Mejía) a dar por <u>terminado</u> de forma inmediata
      la licencia y, en consecuencia, también el acceso al SERVICIO, sin ningún
      tipo de derecho a devoluciones del precio pagado por este y a ejercer
      derechos demandables, si son necesarios, en contra de dicha
      <strong>competencia desleal</strong> y/o <strong>plagio</strong>.
    </p>
  
    <p>
      El desconocimiento de estos términos <strong>no</strong> dará derecho al
      alumno de exigir devolución alguna del pago realizado.
    </p>
  
    <h1>Material que se entrega</h1>
  
    <p>
      El material que se entrega es para un
      <strong
        >uso exclusivamente <u>individual</u> y beneficio exclusivamente
        personal</strong
      >
      y es de propiedad intelectual de Elaboss Academy (Mariana Durán Mejía);
      quién tiene todos los derechos sobre el mismo.
      <strong
        >Está prohibida su copia, comercialización, distribución, reproducción,
        alquiler, o cualquier otra forma de compartir este material con un
        <u>tercero</u>, sea de forma onerosa o gratuita, total o parcial.</strong
      >
    </p>
  
    <p>
      <strong>En ningún momento el uso de esta licencia </strong>
      <u>es o se entenderá</u> como un derecho que se otorga para la venta directa
      de este material, o para su reventa como obra derivada.
    </p>
  
  
    <h1>Responsabilidad:</h1>
  
    <p>
      El alumno deberá entender que el curso exige unos conocimientos básicos en
      marketing que pueden <strong>no</strong> estar dentro del temario. Y, por
      eso, en la información brindada en la venta se expresa que es un curso para
      coaches, mentoras o consultoras que YA están facturando con sus negocios,
      así sea poquito; algo que está claramente <strong>expuesto</strong> en la
      página de ventas y en el proceso de preguntas que se le hace al alumno antes
      de acceder. Y en este sentido, es responsabilidad del alumno haber
      respondido con total <strong><u>honestidad</u></strong> las preguntas que se
      le hacen y leer toda la página de ventas + sus anexos.
    </p>
  
    <p>
      Elaboss Academy (Mariana Durán Mejía)
      <strong>no puede garantizar</strong> que no se produzcan interrupciones o
      errores en el acceso a la plataforma, dónde se alojarán los contenidos, o en
      dónde se van a tener las apariciones de ella en la comunidad de Telegram. No
      obstante, existe el firme compromiso, tan pronto se tenga conocimiento de
      tales incidencias, de llevar a cabo todas las actuaciones dirigidas a su
      restablecimiento o reparación, salvo la concurrencia de causas que lo
      imposibiliten o dificulten su ejecución.
    </p>
  
    <p>
      El contenido del curso de Elaboss Academy (Mariana Durán Mejía) es meramente
      orientativo. Por ende, deberá ser utilizado bajo la
      <strong>responsabilidad</strong>
      exclusiva de su receptor.
    </p>
  
    <p>
      Y, por eso, se advierte de forma expresa que los Contenidos son consejos,
      recomendaciones o sugerencias de profesionales y/o usuarios basados en su
      propia experiencia;
      <strong
        >no garantizándose, bajo ningún concepto, un resultado específico</strong
      >
      luego de que el servicio haya sido prestado y de que el programa haya sido
      dictado, porque los resultados del alumno están sujetos al propio esfuerzo y
      disciplina que se pone en implementar y poner en práctica los pasos que se
      ponen a su disposición dentro del programa.
    </p>
  
    <p>
      Elaboss Academy (Mariana Durán Mejía) únicamente garantiza la prestación del
      servicio para proporcionar una formación, a través del curso.
    </p>
  
    <p>
      Usted es el único responsable de implementar lo aprendido en su propio
      negocio, como tal. Usted acepta que Elaboss Academy (Mariana Durán Mejía) no
      es, ni será responsable de ninguna acción o inacción, incluidos los efectos
      en su negocio, vida personal o carrera, o por cualquier resultado directo o
      indirecto de los Servicios proporcionados por Elaboss Academy (Mariana Durán
      Mejía).
    </p>
  
    <p>
      Usted acepta comunicarse con honestidad, estar abierto a recibir comentarios
      y asistencia y tener el tiempo y la energía necesarios para participar
      activamente en el curso y llevarlo a cabo de forma COMPLETA.
    </p>
  
    <h1>Acuerdo términos y condiciones:</h1>
  
    <p>
      Para poder completar el proceso de <strong>comprar</strong> y
      <strong>acceder</strong> a la prestación del servicio del curso (JHTS) de
      Elaboss Academy (Mariana Durán Mejía) el alumno <strong>acepta</strong> y
      <strong>reconoce</strong> que fue totalmente consciente y 100% responsable
      de haber leído y aceptado estar <u>de acuerdo</u> con los presentes
      <strong>términos y condiciones</strong> del curso (JHTS), antes de completar
      su compra, seleccionando el recuadro de “acepto los términos y condiciones”.
    </p>
  
    <h1>Aceptación del contrato:</h1>
  
    <p>
      Al haber aceptado los <strong>términos y condiciones</strong>, el alumno
      <strong>acepta</strong> de forma totalmente consciente y responsable, dar
      inicio al servicio del curso (JHTS) y todo lo demás relacionado a dicho
      curso; y también acepta estar de acuerdo con el
      <strong><u>contrato</u></strong> de <strong>JHTS</strong> que se entiende que
      ha leído y aceptado, junto con estos
      <strong>términos y condiciones</strong>, antes de completar su compra.
    </p>
  
    <h1>Juramento de mayoría de edad para pagos:</h1>
  
    <p>
      Al haber aceptado los <strong>términos y condiciones</strong>, el alumno
      <strong>acepta</strong>, de forma totalmente consciente y responsable ser
      mayor de edad para poder pagar el curso o tener el consentimiento de un
      adulto mayor para poder pagar el curso. En este sentido, el alumno acepta
      que Elaboss Academy (Mariana Durán Mejía)
      <strong>NO se hace responsable sobre cualquier tercero perjudicado</strong>,
      de ninguna forma o manera, por compras
      <strong>sin consentimiento</strong> de un adulto mayor, ya que es algo que
      está fuera del control de Elaboss Academy (Mariana Durán Mejía) y su equipo.
    </p>
  
    <h1>Cambios en los terminos y condiciones:</h1>
  
    <p>
      El alumno tiene la responsabilidad de estar revisando los presentes
      <strong>términos y condiciones</strong> del curso (JHTS), ya que, en
      cualquier momento, estos pueden ser modificados y deberá tener conocimiento
      de ellos.
    </p>
  </div>
  