import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NmsComponent } from './nms/nms.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormComponent } from './shared/components/forms/personal-data-form/personal-data-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UsersCRUDService } from './shared/services/usersCRUD.service';
import { StripeService } from './shared/services/stripe.service';
import { ModalService } from './shared/services/modal.service';
import { CheckoutComponent } from './shared/components/buyingProcess/checkouts/checkout/checkout.component';
import { LoadScriptsService } from './shared/services/loadScripts.service';
import { CompleteComponent } from './shared/components/buyingProcess/complete/pay-resource/complete.component';
// import { AdminModule } from './admin/admin.module';
// import { ProductsModule } from './products/products.module';
import { EnrolledComponent } from './shared/components/buyingProcess/enrolled/enrolled.component';
import { WaitingComponent } from './shared/components/waiting/waiting.component';
import { CheckoutManychatComponent } from './shared/components/buyingProcess/checkouts/checkoutManychat/checkoutManychat.component';
// import { FilterPipe } from './shared/pipes/filter.pipe';
import { CheckProductService } from './shared/services/checkProduct.service';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { ChangeInformService } from './shared/services/change-inform.service';
import { InfoComponent } from './shared/components/info/info.component';
import { FreeResourceComponent } from './shared/components/buyingProcess/complete/free-resource/free-resource.component';
import { PrivacidadComponent } from './shared/politicas/privacidad/privacidad.component';
import { TerminosComponent } from './shared/politicas/terminos/terminos.component';
import { CheckoutLinkComponent } from './shared/components/buyingProcess/checkouts/checkoutLink/checkout-link/checkout-link.component';
import { EnableCookiesComponent } from './shared/components/enable-cookies/enable-cookies.component';
import { TycJHTSComponent } from './shared/components/tyc/tyc-jhts/tyc-jhts.component';
import { RedirectTelegramComponent } from './shared/components/telegram/redirect-telegram/redirect-telegram.component';
import { CheckoutSubscriptionsComponent } from './shared/components/buyingProcess/checkouts/checkout-subscriptions/checkout-subscriptions.component';
import { CreateSubscriptionFormComponent } from './shared/components/forms/create-subscription-form/create-subscription-form.component';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { PipesModule } from './shared/pipes/pipes.module';


@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    CheckoutComponent,
    CheckoutManychatComponent,
    CompleteComponent,
    EnrolledComponent,
    WaitingComponent,
    InfoComponent,
    FreeResourceComponent,
    PrivacidadComponent,
    TerminosComponent,
    CheckoutLinkComponent,
    EnableCookiesComponent,
    TycJHTSComponent,
    RedirectTelegramComponent,
    CheckoutSubscriptionsComponent,
    CreateSubscriptionFormComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PipesModule
    
    // AdminModule,
    // ProductsModule,
    // PixelModule.forRoot({ enabled: true, pixelId: ['1005719390453090'] }),
  ],
  exports:[ ],
  providers: [
    UsersCRUDService,
    StripeService,
    ModalService,
    LoadScriptsService,
    ChangeInformService,
    CheckProductService,
    {provide:HTTP_INTERCEPTORS,
    useClass:AuthInterceptor,
    multi:true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
