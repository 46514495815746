import { CountryInterface } from "./interfaces/types";

const COUNTRIES: CountryInterface[] = 

[
  {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF",
      "flag": "\ud83c\udde6\ud83c\uddeb",
      "search": "+93 AF"
  },
  {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL",
      "flag": "\ud83c\udde6\ud83c\uddf1",
      "search": "+355 AL"
  },
  {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ",
      "flag": "\ud83c\udde9\ud83c\uddff",
      "search": "+213 DZ"
  },
  {
      "name": "AmericanSamoa",
      "dial_code": "+1684",
      "code": "AS",
      "flag": "\ud83c\udde6\ud83c\uddf8",
      "search": "+1684 AS"
  },
  {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD",
      "flag": "\ud83c\udde6\ud83c\udde9",
      "search": "+376 AD"
  },
  {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO",
      "flag": "\ud83c\udde6\ud83c\uddf4",
      "search": "+244 AO"
  },
  {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI",
      "flag": "\ud83c\udde6\ud83c\uddee",
      "search": "+1264 AI"
  },
  {
      "name": "Antarctica",
      "dial_code": "+672",
      "code": "AQ",
      "flag": "\ud83c\udde6\ud83c\uddf6",
      "search": "+672 AQ"
  },
  {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG",
      "flag": "\ud83c\udde6\ud83c\uddec",
      "search": "+1268 AG"
  },
  {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR",
      "flag": "\ud83c\udde6\ud83c\uddf7",
      "search": "+54 AR"
  },
  {
      "name": "Armenia",
      "dial_code": "+374",
      "code": "AM",
      "flag": "\ud83c\udde6\ud83c\uddf2",
      "search": "+374 AM"
  },
  {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW",
      "flag": "\ud83c\udde6\ud83c\uddfc",
      "search": "+297 AW"
  },
  {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU",
      "preferred": true,
      "flag": "\ud83c\udde6\ud83c\uddfa",
      "search": "+61 AU"
  },
  {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT",
      "flag": "\ud83c\udde6\ud83c\uddf9",
      "search": "+43 AT"
  },
  {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ",
      "flag": "\ud83c\udde6\ud83c\uddff",
      "search": "+994 AZ"
  },
  {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS",
      "flag": "\ud83c\udde7\ud83c\uddf8",
      "search": "+1242 BS"
  },
  {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH",
      "flag": "\ud83c\udde7\ud83c\udded",
      "search": "+973 BH"
  },
  {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD",
      "flag": "\ud83c\udde7\ud83c\udde9",
      "search": "+880 BD"
  },
  {
      "name": "Barbados",
      "dial_code": "+1246",
      "code": "BB",
      "flag": "\ud83c\udde7\ud83c\udde7",
      "search": "+1246 BB"
  },
  {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY",
      "flag": "\ud83c\udde7\ud83c\uddfe",
      "search": "+375 BY"
  },
  {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE",
      "flag": "\ud83c\udde7\ud83c\uddea",
      "search": "+32 BE"
  },
  {
      "name": "Belize",
      "dial_code": "+501",
      "code": "BZ",
      "flag": "\ud83c\udde7\ud83c\uddff",
      "search": "+501 BZ"
  },
  {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ",
      "flag": "\ud83c\udde7\ud83c\uddef",
      "search": "+229 BJ"
  },
  {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM",
      "flag": "\ud83c\udde7\ud83c\uddf2",
      "search": "+1441 BM"
  },
  {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT",
      "flag": "\ud83c\udde7\ud83c\uddf9",
      "search": "+975 BT"
  },
  {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO",
      "flag": "\ud83c\udde7\ud83c\uddf4",
      "search": "+591 BO"
  },
  {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA",
      "flag": "\ud83c\udde7\ud83c\udde6",
      "search": "+387 BA"
  },
  {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW",
      "flag": "\ud83c\udde7\ud83c\uddfc",
      "search": "+267 BW"
  },
  {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR",
      "flag": "\ud83c\udde7\ud83c\uddf7",
      "search": "+55 BR"
  },
  {
      "name": "British Indian Ocean Territory",
      "dial_code": "+246",
      "code": "IO",
      "flag": "\ud83c\uddee\ud83c\uddf4",
      "search": "+246 IO"
  },
  {
      "name": "Brunei Darussalam",
      "dial_code": "+673",
      "code": "BN",
      "flag": "\ud83c\udde7\ud83c\uddf3",
      "search": "+673 BN"
  },
  {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG",
      "flag": "\ud83c\udde7\ud83c\uddec",
      "search": "+359 BG"
  },
  {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF",
      "flag": "\ud83c\udde7\ud83c\uddeb",
      "search": "+226 BF"
  },
  {
      "name": "Burundi",
      "dial_code": "+257",
      "code": "BI",
      "flag": "\ud83c\udde7\ud83c\uddee",
      "search": "+257 BI"
  },
  {
      "name": "Cambodia",
      "dial_code": "+855",
      "code": "KH",
      "flag": "\ud83c\uddf0\ud83c\udded",
      "search": "+855 KH"
  },
  {
      "name": "Cameroon",
      "dial_code": "+237",
      "code": "CM",
      "flag": "\ud83c\udde8\ud83c\uddf2",
      "search": "+237 CM"
  },
  {
      "name": "Canada",
      "dial_code": "+1",
      "code": "CA",
      "flag": "\ud83c\udde8\ud83c\udde6",
      "search": "+1 CA"
  },
  {
      "name": "Cape Verde",
      "dial_code": "+238",
      "code": "CV",
      "flag": "\ud83c\udde8\ud83c\uddfb",
      "search": "+238 CV"
  },
  {
      "name": "Cayman Islands",
      "dial_code": "+345",
      "code": "KY",
      "flag": "\ud83c\uddf0\ud83c\uddfe",
      "search": "+345 KY"
  },
  {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF",
      "flag": "\ud83c\udde8\ud83c\uddeb",
      "search": "+236 CF"
  },
  {
      "name": "Chad",
      "dial_code": "+235",
      "code": "TD",
      "flag": "\ud83c\uddf9\ud83c\udde9",
      "search": "+235 TD"
  },
  {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL",
      "flag": "\ud83c\udde8\ud83c\uddf1",
      "search": "+56 CL"
  },
  {
      "name": "China",
      "dial_code": "+86",
      "code": "CN",
      "flag": "\ud83c\udde8\ud83c\uddf3",
      "search": "+86 CN"
  },
  {
      "name": "Christmas Island",
      "dial_code": "+61",
      "code": "CX",
      "flag": "\ud83c\udde8\ud83c\uddfd",
      "search": "+61 CX"
  },
  {
      "name": "Cocos (Keeling) Islands",
      "dial_code": "+61",
      "code": "CC",
      "flag": "\ud83c\udde8\ud83c\udde8",
      "search": "+61 CC"
  },
  {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO",
      "flag": "\ud83c\udde8\ud83c\uddf4",
      "search": "+57 CO"
  },
  {
      "name": "Comoros",
      "dial_code": "+269",
      "code": "KM",
      "flag": "\ud83c\uddf0\ud83c\uddf2",
      "search": "+269 KM"
  },
  {
      "name": "Congo",
      "dial_code": "+242",
      "code": "CG",
      "flag": "\ud83c\udde8\ud83c\uddec",
      "search": "+242 CG"
  },
  {
      "name": "Congo, The Democratic Republic of the",
      "dial_code": "+243",
      "code": "CD",
      "flag": "\ud83c\udde8\ud83c\udde9",
      "search": "+243 CD"
  },
  {
      "name": "Cook Islands",
      "dial_code": "+682",
      "code": "CK",
      "flag": "\ud83c\udde8\ud83c\uddf0",
      "search": "+682 CK"
  },
  {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR",
      "flag": "\ud83c\udde8\ud83c\uddf7",
      "search": "+506 CR"
  },
  {
      "name": "Cote d'Ivoire",
      "dial_code": "+225",
      "code": "CI",
      "flag": "\ud83c\udde8\ud83c\uddee",
      "search": "+225 CI"
  },
  {
      "name": "Croatia",
      "dial_code": "+385",
      "code": "HR",
      "flag": "\ud83c\udded\ud83c\uddf7",
      "search": "+385 HR"
  },
  {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU",
      "flag": "\ud83c\udde8\ud83c\uddfa",
      "search": "+53 CU"
  },
  {
      "name": "Cyprus",
      "dial_code": "+357",
      "code": "CY",
      "flag": "\ud83c\udde8\ud83c\uddfe",
      "search": "+357 CY"
  },
  {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ",
      "flag": "\ud83c\udde8\ud83c\uddff",
      "search": "+420 CZ"
  },
  {
      "name": "Cura\u00e7ao",
      "dial_code": "+599",
      "code": "CW",
      "flag": "\ud83c\udde8\ud83c\uddfc",
      "search": "+599 CW"
  },
  {
      "name": "Canary Islands",
      "dial_code": "+34",
      "code": "IC",
      "flag": "\ud83c\uddee\ud83c\udde8",
      "search": "+34 IC"
  },
  {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK",
      "flag": "\ud83c\udde9\ud83c\uddf0",
      "search": "+45 DK"
  },
  {
      "name": "Djibouti",
      "dial_code": "+253",
      "code": "DJ",
      "flag": "\ud83c\udde9\ud83c\uddef",
      "search": "+253 DJ"
  },
  {
      "name": "Dominica",
      "dial_code": "+1767",
      "code": "DM",
      "flag": "\ud83c\udde9\ud83c\uddf2",
      "search": "+1767 DM"
  },
  {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO",
      "flag": "\ud83c\udde9\ud83c\uddf4",
      "country_code": "+1",
      "area_codes": [
          "849",
          "829",
          "809"
      ],
      "search": "+1849 DO"
  },
  {
      "name": "Dominican Republic",
      "dial_code": "+1829",
      "code": "DO",
      "flag": "\ud83c\udde9\ud83c\uddf4",
      "secondary": true,
      "search": "+1829 DO"
  },
  {
      "name": "Dominican Republic",
      "dial_code": "+1809",
      "code": "DO",
      "flag": "\ud83c\udde9\ud83c\uddf4",
      "secondary": true,
      "search": "+1809 DO"
  },
  {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC",
      "flag": "\ud83c\uddea\ud83c\udde8",
      "search": "+593 EC"
  },
  {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG",
      "flag": "\ud83c\uddea\ud83c\uddec",
      "search": "+20 EG"
  },
  {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV",
      "flag": "\ud83c\uddf8\ud83c\uddfb",
      "search": "+503 SV"
  },
  {
      "name": "Equatorial Guinea",
      "dial_code": "+240",
      "code": "GQ",
      "flag": "\ud83c\uddec\ud83c\uddf6",
      "search": "+240 GQ"
  },
  {
      "name": "Eritrea",
      "dial_code": "+291",
      "code": "ER",
      "flag": "\ud83c\uddea\ud83c\uddf7",
      "search": "+291 ER"
  },
  {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE",
      "flag": "\ud83c\uddea\ud83c\uddea",
      "search": "+372 EE"
  },
  {
      "name": "Ethiopia",
      "dial_code": "+251",
      "code": "ET",
      "flag": "\ud83c\uddea\ud83c\uddf9",
      "search": "+251 ET"
  },
  {
      "name": "Falkland Islands (Malvinas)",
      "dial_code": "+500",
      "code": "FK",
      "flag": "\ud83c\uddeb\ud83c\uddf0",
      "search": "+500 FK"
  },
  {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO",
      "flag": "\ud83c\uddeb\ud83c\uddf4",
      "search": "+298 FO"
  },
  {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ",
      "flag": "\ud83c\uddeb\ud83c\uddef",
      "search": "+679 FJ"
  },
  {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI",
      "flag": "\ud83c\uddeb\ud83c\uddee",
      "search": "+358 FI"
  },
  {
      "name": "France",
      "dial_code": "+33",
      "code": "FR",
      "flag": "\ud83c\uddeb\ud83c\uddf7",
      "search": "+33 FR"
  },
  {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF",
      "flag": "\ud83c\uddec\ud83c\uddeb",
      "search": "+594 GF"
  },
  {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF",
      "flag": "\ud83c\uddf5\ud83c\uddeb",
      "search": "+689 PF"
  },
  {
      "name": "Gabon",
      "dial_code": "+241",
      "code": "GA",
      "flag": "\ud83c\uddec\ud83c\udde6",
      "search": "+241 GA"
  },
  {
      "name": "Gambia",
      "dial_code": "+220",
      "code": "GM",
      "flag": "\ud83c\uddec\ud83c\uddf2",
      "search": "+220 GM"
  },
  {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE",
      "flag": "\ud83c\uddec\ud83c\uddea",
      "search": "+995 GE"
  },
  {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE",
      "flag": "\ud83c\udde9\ud83c\uddea",
      "search": "+49 DE"
  },
  {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH",
      "flag": "\ud83c\uddec\ud83c\udded",
      "search": "+233 GH"
  },
  {
      "name": "Gibraltar",
      "dial_code": "+350",
      "code": "GI",
      "flag": "\ud83c\uddec\ud83c\uddee",
      "search": "+350 GI"
  },
  {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR",
      "flag": "\ud83c\uddec\ud83c\uddf7",
      "search": "+30 GR"
  },
  {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL",
      "flag": "\ud83c\uddec\ud83c\uddf1",
      "search": "+299 GL"
  },
  {
      "name": "Grenada",
      "dial_code": "+1473",
      "code": "GD",
      "flag": "\ud83c\uddec\ud83c\udde9",
      "search": "+1473 GD"
  },
  {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP",
      "flag": "\ud83c\uddec\ud83c\uddf5",
      "search": "+590 GP"
  },
  {
      "name": "Guam",
      "dial_code": "+1671",
      "code": "GU",
      "flag": "\ud83c\uddec\ud83c\uddfa",
      "search": "+1671 GU"
  },
  {
      "name": "Guatemala",
      "dial_code": "+502",
      "code": "GT",
      "flag": "\ud83c\uddec\ud83c\uddf9",
      "search": "+502 GT"
  },
  {
      "name": "Guernsey",
      "dial_code": "+44",
      "code": "GG",
      "flag": "\ud83c\uddec\ud83c\uddec",
      "search": "+44 GG"
  },
  {
      "name": "Guinea",
      "dial_code": "+224",
      "code": "GN",
      "flag": "\ud83c\uddec\ud83c\uddf3",
      "search": "+224 GN"
  },
  {
      "name": "Guinea-Bissau",
      "dial_code": "+245",
      "code": "GW",
      "flag": "\ud83c\uddec\ud83c\uddfc",
      "search": "+245 GW"
  },
  {
      "name": "Guyana",
      "dial_code": "+592",
      "code": "GY",
      "flag": "\ud83c\uddec\ud83c\uddfe",
      "search": "+592 GY"
  },
  {
      "name": "Haiti",
      "dial_code": "+509",
      "code": "HT",
      "flag": "\ud83c\udded\ud83c\uddf9",
      "search": "+509 HT"
  },
  {
      "name": "Holy See (Vatican City State)",
      "dial_code": "+379",
      "code": "VA",
      "flag": "\ud83c\uddfb\ud83c\udde6",
      "search": "+379 VA"
  },
  {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN",
      "flag": "\ud83c\udded\ud83c\uddf3",
      "search": "+504 HN"
  },
  {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK",
      "flag": "\ud83c\udded\ud83c\uddf0",
      "search": "+852 HK"
  },
  {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU",
      "flag": "\ud83c\udded\ud83c\uddfa",
      "search": "+36 HU"
  },
  {
      "name": "Iceland",
      "dial_code": "+354",
      "code": "IS",
      "flag": "\ud83c\uddee\ud83c\uddf8",
      "search": "+354 IS"
  },
  {
      "name": "India",
      "dial_code": "+91",
      "code": "IN",
      "preferred": true,
      "flag": "\ud83c\uddee\ud83c\uddf3",
      "search": "+91 IN"
  },
  {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID",
      "flag": "\ud83c\uddee\ud83c\udde9",
      "search": "+62 ID"
  },
  {
      "name": "Iran, Islamic Republic of",
      "dial_code": "+98",
      "code": "IR",
      "flag": "\ud83c\uddee\ud83c\uddf7",
      "search": "+98 IR"
  },
  {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ",
      "flag": "\ud83c\uddee\ud83c\uddf6",
      "search": "+964 IQ"
  },
  {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE",
      "flag": "\ud83c\uddee\ud83c\uddea",
      "search": "+353 IE"
  },
  {
      "name": "Isle of Man",
      "dial_code": "+44",
      "code": "IM",
      "flag": "\ud83c\uddee\ud83c\uddf2",
      "search": "+44 IM"
  },
  {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL",
      "flag": "\ud83c\uddee\ud83c\uddf1",
      "search": "+972 IL"
  },
  {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT",
      "flag": "\ud83c\uddee\ud83c\uddf9",
      "search": "+39 IT"
  },
  {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM",
      "flag": "\ud83c\uddef\ud83c\uddf2",
      "country_code": "+1",
      "area_codes": [
          "876",
          "658"
      ],
      "search": "+1876 JM"
  },
  {
      "name": "Jamaica",
      "dial_code": "+1658",
      "code": "JM",
      "flag": "\ud83c\uddef\ud83c\uddf2",
      "secondary": true,
      "search": "+1658 JM"
  },
  {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP",
      "flag": "\ud83c\uddef\ud83c\uddf5",
      "search": "+81 JP"
  },
  {
      "name": "Jersey",
      "dial_code": "+44",
      "code": "JE",
      "flag": "\ud83c\uddef\ud83c\uddea",
      "search": "+44 JE"
  },
  {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO",
      "flag": "\ud83c\uddef\ud83c\uddf4",
      "search": "+962 JO"
  },
  {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ",
      "flag": "\ud83c\uddf0\ud83c\uddff",
      "search": "+77 KZ"
  },
  {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE",
      "flag": "\ud83c\uddf0\ud83c\uddea",
      "search": "+254 KE"
  },
  {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI",
      "flag": "\ud83c\uddf0\ud83c\uddee",
      "search": "+686 KI"
  },
  {
      "name": "Korea, Democratic People's Republic of",
      "dial_code": "+850",
      "code": "KP",
      "flag": "\ud83c\uddf0\ud83c\uddf5",
      "search": "+850 KP"
  },
  {
      "name": "Korea, Republic of",
      "dial_code": "+82",
      "code": "KR",
      "flag": "\ud83c\uddf0\ud83c\uddf7",
      "search": "+82 KR"
  },
  {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW",
      "flag": "\ud83c\uddf0\ud83c\uddfc",
      "search": "+965 KW"
  },
  {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG",
      "flag": "\ud83c\uddf0\ud83c\uddec",
      "search": "+996 KG"
  },
  {
      "name": "Kosovo",
      "dial_code": "+383",
      "code": "XK",
      "flag": "\ud83c\uddfd\ud83c\uddf0",
      "search": "+383 XK"
  },
  {
      "name": "Lao Peoples Democratic Republic",
      "dial_code": "+856",
      "code": "LA",
      "flag": "\ud83c\uddf1\ud83c\udde6",
      "search": "+856 LA"
  },
  {
      "name": "Latvia",
      "dial_code": "+371",
      "code": "LV",
      "flag": "\ud83c\uddf1\ud83c\uddfb",
      "search": "+371 LV"
  },
  {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB",
      "flag": "\ud83c\uddf1\ud83c\udde7",
      "search": "+961 LB"
  },
  {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS",
      "flag": "\ud83c\uddf1\ud83c\uddf8",
      "search": "+266 LS"
  },
  {
      "name": "Liberia",
      "dial_code": "+231",
      "code": "LR",
      "flag": "\ud83c\uddf1\ud83c\uddf7",
      "search": "+231 LR"
  },
  {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY",
      "flag": "\ud83c\uddf1\ud83c\uddfe",
      "search": "+218 LY"
  },
  {
      "name": "Liechtenstein",
      "dial_code": "+423",
      "code": "LI",
      "flag": "\ud83c\uddf1\ud83c\uddee",
      "search": "+423 LI"
  },
  {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT",
      "flag": "\ud83c\uddf1\ud83c\uddf9",
      "search": "+370 LT"
  },
  {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU",
      "flag": "\ud83c\uddf1\ud83c\uddfa",
      "search": "+352 LU"
  },
  {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO",
      "flag": "\ud83c\uddf2\ud83c\uddf4",
      "search": "+853 MO"
  },
  {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "dial_code": "+389",
      "code": "MK",
      "flag": "\ud83c\uddf2\ud83c\uddf0",
      "search": "+389 MK"
  },
  {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG",
      "flag": "\ud83c\uddf2\ud83c\uddec",
      "search": "+261 MG"
  },
  {
      "name": "Malawi",
      "dial_code": "+265",
      "code": "MW",
      "flag": "\ud83c\uddf2\ud83c\uddfc",
      "search": "+265 MW"
  },
  {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY",
      "flag": "\ud83c\uddf2\ud83c\uddfe",
      "search": "+60 MY"
  },
  {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV",
      "flag": "\ud83c\uddf2\ud83c\uddfb",
      "search": "+960 MV"
  },
  {
      "name": "Mali",
      "dial_code": "+223",
      "code": "ML",
      "flag": "\ud83c\uddf2\ud83c\uddf1",
      "search": "+223 ML"
  },
  {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT",
      "flag": "\ud83c\uddf2\ud83c\uddf9",
      "search": "+356 MT"
  },
  {
      "name": "Marshall Islands",
      "dial_code": "+692",
      "code": "MH",
      "flag": "\ud83c\uddf2\ud83c\udded",
      "search": "+692 MH"
  },
  {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ",
      "flag": "\ud83c\uddf2\ud83c\uddf6",
      "search": "+596 MQ"
  },
  {
      "name": "Mauritania",
      "dial_code": "+222",
      "code": "MR",
      "flag": "\ud83c\uddf2\ud83c\uddf7",
      "search": "+222 MR"
  },
  {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "MU",
      "flag": "\ud83c\uddf2\ud83c\uddfa",
      "search": "+230 MU"
  },
  {
      "name": "Mayotte",
      "dial_code": "+262",
      "code": "YT",
      "flag": "\ud83c\uddfe\ud83c\uddf9",
      "search": "+262 YT"
  },
  {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX",
      "flag": "\ud83c\uddf2\ud83c\uddfd",
      "search": "+52 MX"
  },
  {
      "name": "Micronesia, Federated States of",
      "dial_code": "+691",
      "code": "FM",
      "flag": "\ud83c\uddeb\ud83c\uddf2",
      "search": "+691 FM"
  },
  {
      "name": "Moldova, Republic of",
      "dial_code": "+373",
      "code": "MD",
      "flag": "\ud83c\uddf2\ud83c\udde9",
      "search": "+373 MD"
  },
  {
      "name": "Monaco",
      "dial_code": "+377",
      "code": "MC",
      "flag": "\ud83c\uddf2\ud83c\udde8",
      "search": "+377 MC"
  },
  {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN",
      "flag": "\ud83c\uddf2\ud83c\uddf3",
      "search": "+976 MN"
  },
  {
      "name": "Montenegro",
      "dial_code": "+382",
      "code": "ME",
      "flag": "\ud83c\uddf2\ud83c\uddea",
      "search": "+382 ME"
  },
  {
      "name": "Montserrat",
      "dial_code": "+1664",
      "code": "MS",
      "flag": "\ud83c\uddf2\ud83c\uddf8",
      "search": "+1664 MS"
  },
  {
      "name": "Morocco",
      "dial_code": "+212",
      "code": "MA",
      "flag": "\ud83c\uddf2\ud83c\udde6",
      "search": "+212 MA"
  },
  {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ",
      "flag": "\ud83c\uddf2\ud83c\uddff",
      "search": "+258 MZ"
  },
  {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM",
      "flag": "\ud83c\uddf2\ud83c\uddf2",
      "search": "+95 MM"
  },
  {
      "name": "Namibia",
      "dial_code": "+264",
      "code": "NA",
      "flag": "\ud83c\uddf3\ud83c\udde6",
      "search": "+264 NA"
  },
  {
      "name": "Nauru",
      "dial_code": "+674",
      "code": "NR",
      "flag": "\ud83c\uddf3\ud83c\uddf7",
      "search": "+674 NR"
  },
  {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP",
      "flag": "\ud83c\uddf3\ud83c\uddf5",
      "search": "+977 NP"
  },
  {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL",
      "flag": "\ud83c\uddf3\ud83c\uddf1",
      "search": "+31 NL"
  },
  {
      "name": "Caribbean Netherlands",
      "dial_code": "+599",
      "code": "BQ",
      "flag": "\ud83c\udde7\ud83c\uddf6",
      "search": "+599 BQ"
  },
  {
      "name": "New Caledonia",
      "dial_code": "+687",
      "code": "NC",
      "flag": "\ud83c\uddf3\ud83c\udde8",
      "search": "+687 NC"
  },
  {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ",
      "flag": "\ud83c\uddf3\ud83c\uddff",
      "search": "+64 NZ"
  },
  {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI",
      "flag": "\ud83c\uddf3\ud83c\uddee",
      "search": "+505 NI"
  },
  {
      "name": "Niger",
      "dial_code": "+227",
      "code": "NE",
      "flag": "\ud83c\uddf3\ud83c\uddea",
      "search": "+227 NE"
  },
  {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG",
      "flag": "\ud83c\uddf3\ud83c\uddec",
      "search": "+234 NG"
  },
  {
      "name": "Niue",
      "dial_code": "+683",
      "code": "NU",
      "flag": "\ud83c\uddf3\ud83c\uddfa",
      "search": "+683 NU"
  },
  {
      "name": "Norfolk Island",
      "dial_code": "+672",
      "code": "NF",
      "flag": "\ud83c\uddf3\ud83c\uddeb",
      "search": "+672 NF"
  },
  {
      "name": "Northern Mariana Islands",
      "dial_code": "+1670",
      "code": "MP",
      "flag": "\ud83c\uddf2\ud83c\uddf5",
      "search": "+1670 MP"
  },
  {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO",
      "flag": "\ud83c\uddf3\ud83c\uddf4",
      "search": "+47 NO"
  },
  {
      "name": "Oman",
      "dial_code": "+968",
      "code": "OM",
      "flag": "\ud83c\uddf4\ud83c\uddf2",
      "search": "+968 OM"
  },
  {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK",
      "flag": "\ud83c\uddf5\ud83c\uddf0",
      "search": "+92 PK"
  },
  {
      "name": "Palau",
      "dial_code": "+680",
      "code": "PW",
      "flag": "\ud83c\uddf5\ud83c\uddfc",
      "search": "+680 PW"
  },
  {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS",
      "flag": "\ud83c\uddf5\ud83c\uddf8",
      "search": "+970 PS"
  },
  {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA",
      "flag": "\ud83c\uddf5\ud83c\udde6",
      "search": "+507 PA"
  },
  {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG",
      "flag": "\ud83c\uddf5\ud83c\uddec",
      "search": "+675 PG"
  },
  {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY",
      "flag": "\ud83c\uddf5\ud83c\uddfe",
      "search": "+595 PY"
  },
  {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE",
      "flag": "\ud83c\uddf5\ud83c\uddea",
      "search": "+51 PE"
  },
  {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH",
      "flag": "\ud83c\uddf5\ud83c\udded",
      "search": "+63 PH"
  },
  {
      "name": "Pitcairn",
      "dial_code": "+872",
      "code": "PN",
      "flag": "\ud83c\uddf5\ud83c\uddf3",
      "search": "+872 PN"
  },
  {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL",
      "flag": "\ud83c\uddf5\ud83c\uddf1",
      "search": "+48 PL"
  },
  {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT",
      "flag": "\ud83c\uddf5\ud83c\uddf9",
      "search": "+351 PT"
  },
  {
      "name": "Puerto Rico",
      "dial_code": "+1939",
      "code": "PR",
      "flag": "\ud83c\uddf5\ud83c\uddf7",
      "country_code": "+1",
      "area_codes": [
          "939",
          "787"
      ],
      "search": "+1939 PR"
  },
  {
      "name": "Puerto Rico",
      "dial_code": "+1787",
      "code": "PR",
      "flag": "\ud83c\uddf5\ud83c\uddf7",
      "secondary": true,
      "search": "+1787 PR"
  },
  {
      "name": "Qatar",
      "dial_code": "+974",
      "code": "QA",
      "flag": "\ud83c\uddf6\ud83c\udde6",
      "search": "+974 QA"
  },
  {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO",
      "flag": "\ud83c\uddf7\ud83c\uddf4",
      "search": "+40 RO"
  },
  {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU",
      "flag": "\ud83c\uddf7\ud83c\uddfa",
      "search": "+7 RU"
  },
  {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW",
      "flag": "\ud83c\uddf7\ud83c\uddfc",
      "search": "+250 RW"
  },
  {
      "name": "R\u00e9union",
      "dial_code": "+262",
      "code": "RE",
      "flag": "\ud83c\uddf7\ud83c\uddea",
      "search": "+262 RE"
  },
  {
      "name": "Saint Barth\u00e9lemy",
      "dial_code": "+590",
      "code": "BL",
      "flag": "\ud83c\udde7\ud83c\uddf1",
      "search": "+590 BL"
  },
  {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "dial_code": "+290",
      "code": "SH",
      "flag": "\ud83c\uddf8\ud83c\udded",
      "search": "+290 SH"
  },
  {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN",
      "flag": "\ud83c\uddf0\ud83c\uddf3",
      "search": "+1869 KN"
  },
  {
      "name": "Saint Lucia",
      "dial_code": "+1758",
      "code": "LC",
      "flag": "\ud83c\uddf1\ud83c\udde8",
      "search": "+1758 LC"
  },
  {
      "name": "Saint Martin",
      "dial_code": "+590",
      "code": "MF",
      "flag": "\ud83c\uddf2\ud83c\uddeb",
      "search": "+590 MF"
  },
  {
      "name": "Saint Pierre and Miquelon",
      "dial_code": "+508",
      "code": "PM",
      "flag": "\ud83c\uddf5\ud83c\uddf2",
      "search": "+508 PM"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "dial_code": "+1784",
      "code": "VC",
      "flag": "\ud83c\uddfb\ud83c\udde8",
      "search": "+1784 VC"
  },
  {
      "name": "Samoa",
      "dial_code": "+685",
      "code": "WS",
      "flag": "\ud83c\uddfc\ud83c\uddf8",
      "search": "+685 WS"
  },
  {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM",
      "flag": "\ud83c\uddf8\ud83c\uddf2",
      "search": "+378 SM"
  },
  {
      "name": "Sao Tome and Principe",
      "dial_code": "+239",
      "code": "ST",
      "flag": "\ud83c\uddf8\ud83c\uddf9",
      "search": "+239 ST"
  },
  {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA",
      "flag": "\ud83c\uddf8\ud83c\udde6",
      "search": "+966 SA"
  },
  {
      "name": "Senegal",
      "dial_code": "+221",
      "code": "SN",
      "flag": "\ud83c\uddf8\ud83c\uddf3",
      "search": "+221 SN"
  },
  {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS",
      "flag": "\ud83c\uddf7\ud83c\uddf8",
      "search": "+381 RS"
  },
  {
      "name": "Seychelles",
      "dial_code": "+248",
      "code": "SC",
      "flag": "\ud83c\uddf8\ud83c\udde8",
      "search": "+248 SC"
  },
  {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL",
      "flag": "\ud83c\uddf8\ud83c\uddf1",
      "search": "+232 SL"
  },
  {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG",
      "flag": "\ud83c\uddf8\ud83c\uddec",
      "search": "+65 SG"
  },
  {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK",
      "flag": "\ud83c\uddf8\ud83c\uddf0",
      "search": "+421 SK"
  },
  {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI",
      "flag": "\ud83c\uddf8\ud83c\uddee",
      "search": "+386 SI"
  },
  {
      "name": "Solomon Islands",
      "dial_code": "+677",
      "code": "SB",
      "flag": "\ud83c\uddf8\ud83c\udde7",
      "search": "+677 SB"
  },
  {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO",
      "flag": "\ud83c\uddf8\ud83c\uddf4",
      "search": "+252 SO"
  },
  {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA",
      "flag": "\ud83c\uddff\ud83c\udde6",
      "search": "+27 ZA"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "dial_code": "+500",
      "code": "GS",
      "flag": "\ud83c\uddec\ud83c\uddf8",
      "search": "+500 GS"
  },
  {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES",
      "flag": "\ud83c\uddea\ud83c\uddf8",
      "search": "+34 ES"
  },
  {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK",
      "flag": "\ud83c\uddf1\ud83c\uddf0",
      "search": "+94 LK"
  },
  {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD",
      "flag": "\ud83c\uddf8\ud83c\udde9",
      "search": "+249 SD"
  },
  {
      "name": "South Sudan",
      "dial_code": "+211",
      "code": "SS",
      "flag": "\ud83c\uddf8\ud83c\uddf8",
      "search": "+211 SS"
  },
  {
      "name": "Suri'name",
      "dial_code": "+597",
      "code": "SR",
      "flag": "\ud83c\uddf8\ud83c\uddf7",
      "search": "+597 SR"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "dial_code": "+47",
      "code": "SJ",
      "flag": "\ud83c\uddf8\ud83c\uddef",
      "search": "+47 SJ"
  },
  {
      "name": "Swaziland",
      "dial_code": "+268",
      "code": "SZ",
      "flag": "\ud83c\uddf8\ud83c\uddff",
      "search": "+268 SZ"
  },
  {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE",
      "flag": "\ud83c\uddf8\ud83c\uddea",
      "search": "+46 SE"
  },
  {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH",
      "flag": "\ud83c\udde8\ud83c\udded",
      "search": "+41 CH"
  },
  {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY",
      "flag": "\ud83c\uddf8\ud83c\uddfe",
      "search": "+963 SY"
  },
  {
      "name": "Sint Maarten",
      "dial_code": "+721",
      "code": "SX",
      "flag": "\ud83c\uddf8\ud83c\uddfd",
      "search": "+721 SX"
  },
  {
      "name": "Taiwan, Province of China",
      "dial_code": "+886",
      "code": "TW",
      "flag": "\ud83c\uddf9\ud83c\uddfc",
      "search": "+886 TW"
  },
  {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ",
      "flag": "\ud83c\uddf9\ud83c\uddef",
      "search": "+992 TJ"
  },
  {
      "name": "Tanzania, United Republic of",
      "dial_code": "+255",
      "code": "TZ",
      "flag": "\ud83c\uddf9\ud83c\uddff",
      "search": "+255 TZ"
  },
  {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH",
      "flag": "\ud83c\uddf9\ud83c\udded",
      "search": "+66 TH"
  },
  {
      "name": "Timor-Leste",
      "dial_code": "+670",
      "code": "TL",
      "flag": "\ud83c\uddf9\ud83c\uddf1",
      "search": "+670 TL"
  },
  {
      "name": "Togo",
      "dial_code": "+228",
      "code": "TG",
      "flag": "\ud83c\uddf9\ud83c\uddec",
      "search": "+228 TG"
  },
  {
      "name": "Tokelau",
      "dial_code": "+690",
      "code": "TK",
      "flag": "\ud83c\uddf9\ud83c\uddf0",
      "search": "+690 TK"
  },
  {
      "name": "Tonga",
      "dial_code": "+676",
      "code": "TO",
      "flag": "\ud83c\uddf9\ud83c\uddf4",
      "search": "+676 TO"
  },
  {
      "name": "Trinidad and Tobago",
      "dial_code": "+1868",
      "code": "TT",
      "flag": "\ud83c\uddf9\ud83c\uddf9",
      "search": "+1868 TT"
  },
  {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN",
      "flag": "\ud83c\uddf9\ud83c\uddf3",
      "search": "+216 TN"
  },
  {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR",
      "flag": "\ud83c\uddf9\ud83c\uddf7",
      "search": "+90 TR"
  },
  {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM",
      "flag": "\ud83c\uddf9\ud83c\uddf2",
      "search": "+993 TM"
  },
  {
      "name": "Turks and Caicos Islands",
      "dial_code": "+1649",
      "code": "TC",
      "flag": "\ud83c\uddf9\ud83c\udde8",
      "search": "+1649 TC"
  },
  {
      "name": "Tuvalu",
      "dial_code": "+688",
      "code": "TV",
      "flag": "\ud83c\uddf9\ud83c\uddfb",
      "search": "+688 TV"
  },
  {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG",
      "flag": "\ud83c\uddfa\ud83c\uddec",
      "search": "+256 UG"
  },
  {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA",
      "flag": "\ud83c\uddfa\ud83c\udde6",
      "search": "+380 UA"
  },
  {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE",
      "preferred": true,
      "flag": "\ud83c\udde6\ud83c\uddea",
      "search": "+971 AE"
  },
  {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB",
      "preferred": true,
      "flag": "\ud83c\uddec\ud83c\udde7",
      "search": "+44 GB"
  },
  {
      "name": "United States",
      "dial_code": "+1",
      "code": "US",
      "preferred": true,
      "flag": "\ud83c\uddfa\ud83c\uddf8",
      "search": "+1 US"
  },
  {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY",
      "flag": "\ud83c\uddfa\ud83c\uddfe",
      "search": "+598 UY"
  },
  {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ",
      "flag": "\ud83c\uddfa\ud83c\uddff",
      "search": "+998 UZ"
  },
  {
      "name": "Vanuatu",
      "dial_code": "+678",
      "code": "VU",
      "flag": "\ud83c\uddfb\ud83c\uddfa",
      "search": "+678 VU"
  },
  {
      "name": "Venezuela, Bolivarian Republic of",
      "dial_code": "+58",
      "code": "VE",
      "flag": "\ud83c\uddfb\ud83c\uddea",
      "search": "+58 VE"
  },
  {
      "name": "Viet Nam",
      "dial_code": "+84",
      "code": "VN",
      "flag": "\ud83c\uddfb\ud83c\uddf3",
      "search": "+84 VN"
  },
  {
      "name": "Virgin Islands, British",
      "dial_code": "+1284",
      "code": "VG",
      "flag": "\ud83c\uddfb\ud83c\uddec",
      "search": "+1284 VG"
  },
  {
      "name": "Virgin Islands, U.S.",
      "dial_code": "+1340",
      "code": "VI",
      "flag": "\ud83c\uddfb\ud83c\uddee",
      "search": "+1340 VI"
  },
  {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF",
      "flag": "\ud83c\uddfc\ud83c\uddeb",
      "search": "+681 WF"
  },
  {
      "name": "Western Sarah",
      "dial_code": "+212",
      "code": "EH",
      "flag": "\ud83c\uddea\ud83c\udded",
      "search": "+212 EH"
  },
  {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE",
      "flag": "\ud83c\uddfe\ud83c\uddea",
      "search": "+967 YE"
  },
  {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM",
      "flag": "\ud83c\uddff\ud83c\uddf2",
      "search": "+260 ZM"
  },
  {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW",
      "flag": "\ud83c\uddff\ud83c\uddfc",
      "search": "+263 ZW"
  },
  {
      "name": "\u00c5land Islands",
      "dial_code": "+358",
      "code": "AX",
      "flag": "\ud83c\udde6\ud83c\uddfd",
      "search": "+358 AX"
  }
];

export default COUNTRIES;
