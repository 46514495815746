import { HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeInformService {
  constructor() { }

  public changeMenuEvent: EventEmitter<any> = new EventEmitter();
  public changeTopMenuEvent: EventEmitter<any> = new EventEmitter();
  public interceptorEvent :EventEmitter<any> = new EventEmitter();
  public activateTemp :EventEmitter<any> = new EventEmitter();


}
