import { AfterContentInit, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { PathValidationService } from 'src/app/shared/services/path-validation.service';
import { environment } from 'src/environments/environment';
import { getCookie, setCookie } from 'typescript-cookie';

@Component({
  selector: 'app-free-resource',
  templateUrl: './free-resource.component.html',
  styleUrls: ['./free-resource.component.css']
})
export class FreeResourceComponent implements OnInit,OnDestroy {

  constructor(
    private inject: Injector,

  ) { 
    let pathService = this.inject.get(PathValidationService);
    this.product = window.location.pathname.split('/')[1]
    pathService.pathValidator(this.product);

  }
  email! :string|null
  product=''
  ngOnInit(): void {
    this.email= sessionStorage.getItem('email')

    window.onbeforeunload = () => this.ngOnDestroy();
  }
  deleteCookie(product:string){
    const cookieName = '__' + product.toLowerCase() + '_access_time';
    console.log('+++++++++++++++++++++++++');
    console.log(getCookie(cookieName));
    console.log('+++++++++++++++++++++++++');
    
    if(getCookie(cookieName) && getCookie(cookieName)!=null){
      setCookie(cookieName, '', {
        expires: -1,
        path: window.location.pathname,
        domain: environment.domain,
      });
    }

  }
  ngOnDestroy(): void {
    console.log('on Destroy');
    
    this.deleteCookie(this.product)

  }
}
