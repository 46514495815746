import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { WaitingComponent } from '../../../waiting/waiting.component';
import { LoadScriptsService } from 'src/app/shared/services/loadScripts.service';
import { UsefullFunctionsService } from 'src/app/shared/services/usefull-functions.service';

@Component({
  selector: 'app-checkout-subscriptions',
  templateUrl: './checkout-subscriptions.component.html',
  styleUrls: ['./checkout-subscriptions.component.css'],
})
export class CheckoutSubscriptionsComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private dialog: MatDialog,
    // private snack: MatSnackBar,
    private modal: ModalService, // private modal: ModalService
    private inject: Injector
  ) {}

  paynentHandler: any = null;

  price = 0;
  product = '';

  ngOnInit() {}

  ngAfterViewInit(): void {
    const stripe = this.inject.get(StripeService);
    const data = {
      public_key: this.matDialogdata['publishableKey'],
      email: this.matDialogdata['email'],
      installements: this.matDialogdata['installement'],
      cycle: this.matDialogdata['cycle'],
      name: this.matDialogdata['name'],
      mobile : this.matDialogdata['mobile']
    };
    this.createSubscription(data, stripe);
  }
  createSubscription(data: any, stripe: StripeService) {
    console.log('..................in createSubscription........................');
    
    const loadScripts = this.inject.get(LoadScriptsService);
    loadScripts.stripe();
    const script = window.document.getElementById('stripe-script');
    console.log('script',script);
    

    let stripeJS: any;

    script!.onload = () => {
      stripeJS = (<any>window).Stripe(data.public_key);
    };
    console.log('***********************************************************');
    console.log('data',data);
    
    console.log('***********************************************************');
    
    stripe.createSubscription(data).subscribe({
      next: (v) => {
        console.log('in next create Subscription', v.body);

        const { clientSecret, price, product } = v.body as any;

        this.price = price;
        this.product = product;

        const elements = stripeJS.elements({ clientSecret });
        console.log('elements', elements);
        
        const paymentElement = elements.create('payment');

        paymentElement.mount('#payment-element');
    

        const form = document.getElementById('payment-form');

        form!.addEventListener('submit', async (e) => {
          e.preventDefault();
                    
          const { error } = await stripeJS.confirmPayment({
            elements: elements,
            confirmParams: {
              return_url:
                window.location.origin +
                window.location.pathname +
                '/payments/complete',
            },
          });
          if (error) {
            const messages = document.getElementById('error-messages');
            messages!.innerText = error.message;

            this.modal.close(this.dialog);
          }
        });
      },
      complete: () => {},
    });
  }

  processing() {
    const options = {
      disableClose: true,
      autoFocus: true,
      width: 'auto',
      height: 'auto',
      // data: this.matDialogdata,
    };
    this.modal.modalForm(
      this.dialog,
      // this.matDialogdata,
      WaitingComponent,
      options
    );
  }
}
