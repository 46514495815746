<div class="form">
  <h1
    class="font-century text-align-center font-size-x_xrem container c-4E4E4E"
  >
    Datos de contacto
  </h1>

  <picture class="cont_Centrado text-align-justify">
    <img class="container-100-center" id="banner" />
  </picture>

  <mat-form-field>
    <mat-label appearance="fill" class="form-label">Planes:</mat-label>
    <mat-select [formControl]="dataFormGroup.controls.cycle" required>
      <mat-option>--</mat-option>
      <mat-option *ngFor="let cycle of cycles" [value]="cycle['name']">
        {{ cycle["name"] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label appearence="fill" class="form-label"
      >Nombres y apellidos:</mat-label
    >
    <input
      matInput
      placeholder="Nombres y apellidos"
      [formControl]="dataFormGroup.controls.name"
      required
    />
    <mat-error *ngIf="dataFormGroup.controls['name'].invalid"
      >Ingresa tu nombre</mat-error
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label appearance="fill" class="form-label">Correo de gmail:</mat-label>
    <input
      class="input"
      matInput
      placeholder="correo@gmail.com"
      required
      [formControl]="dataFormGroup.controls.email"
    />
    <mat-error *ngIf="dataFormGroup.controls['email'].invalid"
      >Ingresa un correo de gmail.</mat-error
    >
  </mat-form-field>

  <div class="mobile-form">
    <mat-form-field [formGroup]="dataFormGroup" class="">
      <mat-label appearence="" class="form-label">País:</mat-label>
      <mat-error *ngIf="dataFormGroup.controls['country'].invalid"
        >Ingresa un país</mat-error
      >
      <mat-select formControlName="country" id="select">
        <div class="flex-search">
          <input
          class="mat-select-input"
          matInput
          [(ngModel)]="searchBox"
          placeholder="buscar..."
          formControlName="country"
        />
        <span class="material-symbols-outlined" (click)="clearSearchBox()">search</span>          
        </div>



        <mat-option
          *ngFor="let country of countries | filter : searchBox"
          [value]="country.dial_code"
        >
          {{ country.flag }}
          {{ country.dial_code }}
          {{ country.code }}
        </mat-option>
        
      </mat-select>
    </mat-form-field>
    <mat-form-field [formGroup]="dataFormGroup" class="">
      <mat-label appearence="" class="">Contacto:</mat-label>
      <input
        class=""
        matInput
        placeholder="321456789"
        required
        formControlName="mobile"
      />
      <mat-error
        class="break-word"
        *ngIf="dataFormGroup.controls.mobile.invalid"
        >{{ getErrors(dataFormGroup.controls.mobile) }}</mat-error
      >
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label appearance="fill" class="form-label">Cuotas:</mat-label>
    <mat-select [formControl]="dataFormGroup.controls.installement" required>
      <mat-option>--</mat-option>
      <mat-option
        *ngFor="let installement of installements"
        [value]="installement"
      >
        {{ installement["installement"] }} ->
        {{ installement["price"] / 100 }} USD
      </mat-option>
    </mat-select>
    <!-- </form> -->
  </mat-form-field>

  <mat-checkbox
    class="checkbox_margin"
    [formControl]="dataFormGroup.controls.accepts_c"
    required
  >
    <a id="tyc" target="”_blank”" href="">ver aquí TyC</a>
    &nbsp;

    <a id="politica" target="”blank”" href=""
      >ver aquí politica de privacidad de datos</a
    >
  </mat-checkbox>
</div>
<div class="checkout-button">
  <button
    [disabled]="dataFormGroup.invalid"
    (click)="writeData(dataFormGroup)"
    mat-raised-button
  >
    Continuar
  </button>
</div>
