import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class ModalService {
  constructor() {}
  modalForm(
    dialog: MatDialog,
    // data: any,
    component: ComponentType<any>,
    op?: {
      disableClose: boolean;
      autoFocus: boolean;
      width?: string;
      height?: string;
      data?: any;
    }
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = op?.disableClose;
    dialogConfig.autoFocus = op?.autoFocus;
    dialogConfig.width = op?.width;
    dialogConfig.height = op?.height;
    dialogConfig.data = op?.data;
    dialog.open(component, dialogConfig);
  }

  close(dialog: MatDialog) {
    dialog
      .getDialogById(dialog.openDialogs[dialog.openDialogs.length - 1].id)
      ?.close();
  }
}
