<picture class="cont_Centrado text-align-justify">
  <img class="container-100-center" id="product" />
</picture>
<form id="payment-form">
  <div id="payment-element">
    <!-- Elements will create form elements here -->
  </div>
  <div class="checkout-button">
    <button id="submit" (click)="processing()" mat-raised-button>
      Pagar $ {{ price }} usd
    </button>
  </div>
  <div id="error-messages">
    <!-- Display error message to your customers here -->
  </div>
</form>
