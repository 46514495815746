<div class="container enrolado">
  <p id="enroll" class="wtextos">

  </p>

</div>

<div class="container checkout-button">
  <button (click)="close()" mat-raised-button>Entendido</button>
</div>
