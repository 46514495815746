import { Injectable, Injector } from '@angular/core';
import { ModalService } from './modal.service';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from '../components/forms/personal-data-form/personal-data-form.component';
import { ChangeInformService } from './change-inform.service';
import { CheckProductService } from './checkProduct.service';
import { CryptographyService } from './cryptography.service';
import { UsersCRUDService } from './usersCRUD.service';

@Injectable({
  providedIn: 'root',
})
export class UsefullFunctionsService {
  constructor(private inject: Injector) {}
  assignData(arrayOfElement: NodeListOf<Element>, toassign: number) {
    arrayOfElement.forEach((element) => {
      if (toassign > 0) {
        element.innerHTML = toassign.toString();
      } else {
        element.innerHTML = '0';
      }
    });
  }

  setPrice(value: number, product: string) {
    const precio = document.querySelectorAll('#price');
    const valores = document.querySelectorAll('#valor');
    valores.forEach((valor) => {
      valor?.remove();
    });

    let price = (value).toString();
    precio.forEach((element) => {
      element.innerHTML = 'Precio del curso: $' + price + ' usd';
    });

    let modal = this.inject.get(ModalService);

    let dialog = this.inject.get(MatDialog);
    const links = document.querySelectorAll('#link');

    links.forEach((element) => {
      const link = element as HTMLAnchorElement;
      link.style.color = 'blue';
      link.style.textDecorationLine = 'underline';
      link.style.cursor='pointer'

      link!.onclick = () => {

        const options = {
          disableClose: false,
          autoFocus: true,
          width: 'auto',
          height: '650px',
          data: {
            price: value,
            product: product,
            img: '../../../../assets/images/Banner landing ' + product + '.jpg',
          },
        };
        modal.modalForm(dialog, FormComponent, options);
      };
    });
  }
  process(product: string, activateTemp: boolean) {
    let usefull = this.inject.get(UsefullFunctionsService);
    const info = usefull.__getSessionStorageData();

    if ('code' in info) {
      let encodedEncryptedData = sessionStorage.getItem('code');
      if (encodedEncryptedData != null) {
        let cryptographyService = this.inject.get(CryptographyService);
        const message = cryptographyService.getmessage(encodedEncryptedData);
        sessionStorage.setItem('email', message['user']);
        if ('price' in message) {
          this.setPrice(message['price'], product);
        }
      }
    } else {
      let _product = this.inject.get(UsersCRUDService);
      _product.getProducts(product).subscribe({
        next: (v) => {
          if ((v.body as any)['products'].length > 0) {
            const value = (v.body as any)['products'][0][
              activateTemp ? 'sale_price' : 'full_price'
            ] as number;
            if (value != undefined) {
              this.setPrice(value, product);
            }
          }
        },
        error: (err) => {
          console.log('error', err);
        },
      });
    }
  }

  updateCountdown(
    SPAN_DAYS: NodeListOf<Element>,
    SPAN_HOURS: NodeListOf<Element>,
    SPAN_MIN: NodeListOf<Element>,
    SPAN_S: NodeListOf<Element>,
    MS_OF_A_DAY: number,
    MS_OF_A_HOUR: number,
    MS_OF_A_MIN: number,
    MS_OF_A_S: number,
    LIMIT: number,
    product: string =''
  ) {
    let intervalId = setInterval(() => {
      // Calcs
      const NOW: number = Date.parse(new Date().toISOString());

      // const DURATION = this.DATE_TARGET - NOW;
      const DURATION = LIMIT - NOW;
      const RM_DAYS = Math.floor(DURATION / MS_OF_A_DAY);
      const RM_HOURS = Math.floor((DURATION % MS_OF_A_DAY) / MS_OF_A_HOUR);
      const RM_MIN = Math.floor((DURATION % MS_OF_A_HOUR) / MS_OF_A_MIN);
      const RM_S = Math.floor((DURATION % MS_OF_A_MIN) / MS_OF_A_S);
      let usefull = this.inject.get(UsefullFunctionsService);
      usefull.assignData(SPAN_DAYS, RM_DAYS);
      usefull.assignData(SPAN_HOURS, RM_HOURS);
      usefull.assignData(SPAN_MIN, RM_MIN);
      usefull.assignData(SPAN_S, RM_S);

      if (RM_DAYS <= 0 && RM_HOURS <= 0 && RM_MIN <= 0 && RM_S <= 0) {
        let prices: any;
        let changeInform = this.inject.get(ChangeInformService);
        changeInform.activateTemp.emit(false);
        
        // this.activateTemp = false;
        // let activateTemp = false;
        if(product!=''){
          let checkProduct = this.inject.get(CheckProductService);
          checkProduct.getProduct(product).subscribe({
            next: (v) => {
              prices = (v.body as any)['prices'];
            },
            complete: () => {
              let usefull = this.inject.get(UsefullFunctionsService);
              usefull.setPrice(prices.full_price, product);
              clearInterval(intervalId);
              // return activateTemp
            },
          });
        }

      }
    });
  }
  __getSessionStorageData() {
    let info: any = {};
    for (let index = 0; index < sessionStorage.length; index++) {
      const key = sessionStorage.key(index)!;
      const value = sessionStorage.getItem(key);
      info[key] = value;
    }
    return info;
  }

  __setCookie(name:string,value:string|null,daysToExpire: number){
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    var cookieString =
      name +
      '=' +
      value +
      '; expires=' +
      expirationDate.toUTCString() +
      '; path=/';
    document.cookie = cookieString;
    return value    
  }
  setTrackingCookie(
    name: string,
    value: string | null,
    daysToExpire: number = 365
  ) {
    if (!document.cookie.includes(name)) {
      return this.__setCookie(name,value,daysToExpire)
    }
    if (!document.cookie.includes(value!)){
      return this.__setCookie(name,value,daysToExpire)

    }
    return null
  }
  getTrackingCookie(name: string) {
    const cookies = document.cookie.split(';');
    
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];

      if(cookie.includes(name + '=')){
        return cookie.split('=')[1];

      }
      // if (cookie.indexOf(name + '=') === 1) {     
      //   return cookie.substring(name.length + 2);
      // }
    }
    return null;
  }
  range(start:number,end:number){
    return Array.from({length:end - start +1},(_,i)=>start +i)
  }
  decrypt(){
    const params = new URLSearchParams(window.location.search);
    let encodedEncryptedData = null;

    // let code = ''
    if (params.toString().length > 0) {
      params.forEach((value: any, key: any) => {
        if (key == 'code') {
          encodedEncryptedData = value;
        }
      });
    }

    let limit = 0;
    let cryptographyService = this.inject.get(CryptographyService);

    const message = (encodedEncryptedData != null) ? cryptographyService.getmessage(encodedEncryptedData):null
    if (message != null) {
      // const message = cryptographyService.getmessage(encodedEncryptedData);
      
      let expiryDate = Date.parse(
        new Date(message['expiry_date']).toUTCString()
      );
      // console.log('expiryDate',expiryDate);

      let today = Date.parse(new Date().toUTCString());
      // const offSet = new Date().getTimezoneOffset()
      // console.log('offset',offSet)
      // console.log('today',new Date(today));
      // console.log('expiryDate-today',expiryDate - today);

      limit = expiryDate - today > 0 ? expiryDate : expiryDate
      // if (expiryDate - today > 0) {
      //   limit = expiryDate;
      // } else {
      //   limit = expiryDate;
      // }
      message['expiry_date'] = new Date(limit)

      return message 
    }

    // console.log('return', {
    //   limit: new Date(limit),
    // });
    // alert(new Date(limit))


    // Falta retornar todo el mensaje para tomar los datos de correo, producto, nombre y tiempo de caducidad.
    return null
  }
}
