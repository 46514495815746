import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoadScriptsService } from 'src/app/shared/services/loadScripts.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { StripeService } from 'src/app/shared/services/stripe.service';

@Component({
  selector: 'app-checkoutManychat',
  templateUrl: './checkoutManychat.component.html',
  styleUrls: ['./checkoutManychat.component.css'],
})
export class CheckoutManychatComponent implements OnInit {
  constructor(
    // @Inject(MAT_DIALOG_DATA) public matDialogdata: any,
    private stripe: StripeService,
    private loadScripts: LoadScriptsService,
    private dialog: MatDialog,
    // private modal: ModalService, // private modal: ModalService
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        if (Object.keys(params).length > 0) {
          console.log('params', params);

          this.clientSecret = params['client'];
        }
      },
    });
  }

  paynentHandler: any = null;
  stripeJS: any = null;
  stripeAPIKey: any = '';
  price = 0;
  product = '';
  publishablekey: string = '';
  clientSecret = '';

  ngOnInit() {
    this.loadScripts.stripe();
    const img = document.querySelector('#product') as HTMLImageElement;
  }

  _getPublishableKey(): Promise<string> {
    return new Promise((accept, reject) => {
      this.stripe.getPublishableKey().subscribe({
        next: (v) => {
          accept((v.body as any)[0]['publishablekey']);
        },
        error: () => {
          reject('none');
        },
      });
    });
  }

  ngAfterContentInit(): void {
    const script = window.document.getElementById('stripe-script');
    let stripeJS: any;
    script!.onload = async () => {
      const publishablekey = await this._getPublishableKey();

      stripeJS = (<any>window).Stripe(publishablekey);
      console.log('stripeJS',stripeJS);
      
      const {paymentIntent} = await stripeJS.retrievePaymentIntent(
        this.clientSecret
      );

      console.log('metadata',paymentIntent.metadata);
      console.log('paymentIntent',paymentIntent);

      if (paymentIntent.status != 'succeeded') {
        this.price = paymentIntent.amount / 100;

        const clientSecret = this.clientSecret;

        const elements = stripeJS.elements({ clientSecret });

        const paymentElement = elements.create('payment');

        paymentElement.mount('#payment-element');

        const form = document.getElementById('payment-form');

        form!.addEventListener('submit', async (e) => {
          e.preventDefault();

          const { error } = await stripeJS.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.origin + '/payments/complete',
            },
          });
          // if (error) {
          //   const messages = document.getElementById('error-messages');
          //   messages!.innerText = error.message;
          //   this.dialog
          //     .getDialogById(
          //       this.dialog.openDialogs[this.dialog.openDialogs.length - 1].id
          //     )
          //     ?.close();
          // }
        });
      }else{
        // Toca crear vista que diga que el pago 
        // ya se a realizado de manera correcta
        // elminar los elementos y crear unos nuevos
        
      }
    };
  }
  // getClientSecret(matDialogdata: any, stripe: StripeService) {
  //   // const script = window.document.getElementById('stripe-script');
  //   // let stripeJS: any;
  //   // script!.onload = () => {
  //   //   stripeJS = (<any>window).Stripe(matDialogdata.publishableKey);
  //   // };
  //   // const stripeJS = (<any>window).Stripe(this.matDialogdata.publishableKey);

  //   stripe.getClientSecret(matDialogdata).subscribe((v) => {
  //     const { clientSecret, price, product } = v.body as any;

  //     this.price = price / 100;
  //     this.product = product;

  //     // const stripe = (<any>window).Stripe(this.matDialogdata.publishableKey);

  //     // const elements = stripeJS.elements({ clientSecret });

  //     // const paymentElement = elements.create('payment');

  //     // paymentElement.mount('#payment-element');

  //     // const form = document.getElementById('payment-form');

  //     // form!.addEventListener('submit', async (e) => {
  //     //   e.preventDefault();

  //     //   const { error } = await stripeJS.confirmPayment({
  //     //     elements,
  //     //     confirmParams: {
  //     //       return_url: window.location.origin+window.location.pathname + '/payments/complete',
  //     //     },
  //     //   });
  //     //   if (error) {
  //     //     const messages = document.getElementById('error-messages');
  //     //     messages!.innerText = error.message;
  //     //     this.dialog
  //     //       .getDialogById(
  //     //         this.dialog.openDialogs[this.dialog.openDialogs.length - 1].id
  //     //       )
  //     //       ?.close();
  //     //   }
  //     // });
  //   });
  // }

  processing() {
    // const options = {
    //   disableClose: true,
    //   autoFocus: true,
    //   width: 'auto',
    //   height: 'auto',
    //   data: this.matDialogdata,
    // };
    // this.modal.modalForm(
    //   this.dialog,
    //   this.matDialogdata,
    //   WaitingComponent,
    //   options
    // );
  }
}
