import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  private loadOk = false;

  constructor(
    private httpClient: HttpClient,

) { }

  sendEvent(data:any){

    const baseUrl = environment['host'] + '/px';
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
    })
    const body = JSON.stringify(data);

    return this.httpClient.post(baseUrl,body,{ headers: headers, observe: 'response' })


  }

  load() {
    // console.log('IN LOAD');
    
    if (!this.loadOk) {
      (function(f: any, b:any, e:any, v:any, n?:any, t?:any, s?:any) { // Getting 'non-arrow functions are forbidden' warning
        if (f.fbq) {
          return;
        }
        n = f.fbq = function() { // Getting Type '() => void' is not assignable to type 'undefined'
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) {
          f._fbq = n;
        }
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e); // Getting Type 'HTMLElement' is not assignable to type 'undefined'
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );
      (window as any).fbq('init', '1005719390453090');
      this.loadOk = true;
      // console.log('Facebook pixel init run!');
    } 
    // else {
    //   (window as any).fbq('track', 'PageView');
    //   // console.log('Facebook PageView event fired!');
    // }
  }
  initiateCheckout(product:string){
    (window as any).fbq('track', 'InitiateCheckout',{content_name:product});
  }
  purchase(product:string, value:number){
    (window as any).fbq('track', 'Purchase',{value: value, currency: 'USD',content_name:product});
  }
  pageView(product:string){
    (window as any).fbq('track', 'PageView',{content_name:product});
  }
  lead(product:string){
    (window as any).fbq('track', 'Lead',{content_name:product});
  }
  registration(product:string){
    (window as any).fbq('track', 'Registration',{content_name:product});
  }
  

}
