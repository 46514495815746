<picture class="container-wrapper">
  <img
    class="container"
    id=""
    src="../../../../../assets/logos/logo-redondo-b&n.svg"
  />
</picture>

<!-- <div class="container-wrapper">
    <picture class="container">
        <img
          id=""
          src="../../../../../assets/logos/logo-redondo-b&n.svg"
          
        />
      </picture>
</div>
 -->

<!-- /src/assets/logos/logo-redondo-b&n.svg -->

<!-- src="../../../assets/logos/logo-redondo-b&n.svg" -->
